import { Col, Row, Spin, Typography } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { FC, useEffect } from 'react'
import { Rate } from 'services/game'
import Rating from 'react-rating-stars-component'
import moment from 'moment'
import usePlayer from 'services/player'
import { getAvatar } from 'services/openid'
export interface ListItemProps {
  review: Rate
}
const ListItem: FC<ListItemProps> = ({ review }) => {
  const { player, loading, get } = usePlayer(review.player.username)
  useEffect(() => {
    get()
  }, [review])
  return (
    <div style={{
      marginLeft: 10,
      marginTop: 10,
      marginBottom: 10
    }}>
      <Row>
        <Col xs={2} xl={1}>
          <Avatar src={player && getAvatar(review.player.username)} />
        </Col>
        <Col xs={22} xl={23}>
          {
            loading && <Spin />
          }
          {
            !loading &&
            <div>
            <Row >
              <Col span={12}>
                <Typography.Text strong={true}>{player && player.fullname}</Typography.Text>
                <Rating
                  style={{
                    margin: '0 auto'
                  }}
                  count={5}
                  size={24}
                  activeColor="#00875F"
                  value={review.rate}

                  edit={false}
                />
              </Col>
              <Col span={12} style={{textAlign: 'right'}}>
                <Typography.Text>{moment(review.created_at).format('dddd, DD MMMM YYYY')}</Typography.Text>
              </Col>
            </Row>
            </div>}
          <Typography.Paragraph>
            {review.review}
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
  )
}

export default ListItem
