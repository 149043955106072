import React, { useEffect, useState } from 'react'
import { useGlobalState } from '../store'
import './overlay.scss'
export const OVERLAY_GS = 'overlay'
export default () => {
  const [overlay] = useGlobalState(OVERLAY_GS, false)
  const [size, setSize] = useState({
    width: 0, height: 0
  })
  useEffect(() => {
    const [width, height] = [window.screen.width, window.screen.height]
    setSize({
      width, height
    })
  }, [])
  return (
    <div id="overlay" style={{
      width: size.width,
      height: size.height,
      transition: '0.2s',
      display: !overlay ? 'none': 'inline' 
    }}></div>
  )
}