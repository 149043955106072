import { BellFilled } from '@ant-design/icons'
import { Badge, Button, Popover, Spin } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification, NotificationItem } from 'services/notification'
import qs from 'query-string'
import moment from 'moment'
import {Typography} from 'antd'
import { useHistory } from 'react-router-dom'
import { useInterval } from 'services/utils'
const {Text, Paragraph} = Typography
const dateFormat = "hh:mm, dddd, DD MMMM YYYY"
export interface NotificationProps {

}

export interface NotificationItemProps {
  notification: NotificationItem
  onNotificationRead?: Function
}

export const NotificationItemComponent: FC<NotificationItemProps> = ({
  notification,
  onNotificationRead
}) => {
  const notificationService = useNotification()

  const [readed, setReaded] = useState(notification.readed)
  const history = useHistory()
  const onClick = async () => {
    if(!notification.readed){
      await notificationService.readNotification(notification.id)
      onNotificationRead && onNotificationRead()
    } 
    setReaded(true)
    const payload = qs.parse(notification.param)
    if (notification.action === "game") {
      history.push(`/publishers/${payload.publisher_username}/games/${payload.game_name}/info`)
    } else if (notification.action === "game:event") {
      history.push(`/publishers/${payload.publisher_username}/games/${payload.game_name}/events/${payload.event_name}`)
    } else if (notification.action === "external") {
      window.location.href = payload.url as string
    }
  }

  return (
    <li
      style={{ borderRight: !readed ? "3px solid #0277BD": '', textDecoration: 'none', cursor: 'pointer', borderBottom: '1px solid #a0a0a0'}}
      onClick={onClick}
    >
      <Text ellipsis type='success'>
        {notification.title}
      </Text >
      {!notification.message ? null : (
        <Paragraph className="gq-notification-item-message" ellipsis>
          {notification.message}
        </Paragraph>
      )}
      <div style={{textAlign: 'right', fontSize: '10px', paddingRight: 10}}>      
        <Text className="gq-notification-item-date">
        {moment(notification.created).fromNow()}
      </Text>
      </div>
    </li>

  )
}

const Notification: FC<NotificationProps> = ({}) => {
  const notificationService = useNotification()
  const {t} = useTranslation()
  const [notifications, setNotifications] = useState<NotificationItem[]>([])
  const [loading, setLoading] = useState(false)
  const [unreadNotifications, setUnreadNotification] = useState(0)
  const componentDidMount = () => {
    getNewest()
    refresh()
  }
  useInterval(() => {
    getNewest()
  }, 7500)
  const getUnread = () => {

    notificationService
    .getUnreadNotification()
    .then((n) => {
      setUnreadNotification(n)
    })
    .catch(() => {

    })
  }
  const getNewest = () => {
    getUnread()
    if(notifications.length === 0) return
    notificationService
    .getNewestNotification(notifications[0].id)
    .then((n) => {
      setNotifications([...n, ...notifications])
    })
    .catch(() => {

    })
  } 
  const refresh = () => {

    setNotifications([])
    setLoading(true)
    notificationService
      .getNotification()
      .then(n => setNotifications(n))
      .catch(e => {})
      .finally(() => setLoading(false))
  }
  const loadNext = () => {
    if(notifications.length === 0) return
    setLoading(true)
    notificationService
      .getNextNotification(notifications[notifications.length-1].id)
      .then(n => setNotifications([...notifications, ...n]))
      .catch(e => {})
      .finally(() => setLoading(false))

  }
  const NotificationList = () => (
    <div style={{overflowY: 'auto', maxHeight: '250px'}}>
      <ul style={{listStyleType: 'none', padding:0}}>
        <li style={{textAlign: 'center'}}>
          <Button 
            type='text'
            onClick={refresh}
          >
            {t('Refresh')}
          </Button>
        </li>
        {
          notifications.map((n, i) => (
            <NotificationItemComponent onNotificationRead={() => setUnreadNotification(unreadNotifications - 1)} notification={n} key={`notification-${i}`}/>
          ))
        }
        {
          loading &&
          <li style={{textAlign: 'center'}}>
            <Spin/>  
          </li>
          }
        {
          notifications.length === 0 && (
            <Text>{t('No Notifications')}</Text>
          )
        }
        {
          notifications.length > 0 && (
            <li style={{textAlign: 'center'}}>
              <Button type='text' onClick={loadNext}>
                {t('Load more')}
              </Button>
            </li>
          )
        }
      </ul>
    </div>
  )
  useEffect(componentDidMount, [])
  
  return (
    <>
        <Popover
        placement="bottomRight"
        trigger="click"
        title={t("Notification")}
        content={NotificationList}
        >
        <Button type="text">
          {unreadNotifications <= 0 ? (
            <BellFilled style={{ fontSize: 16 }} />
          ) : (
            <Badge size="small" count={unreadNotifications}>
              <BellFilled style={{ fontSize: 16 }} />
            </Badge>
          )}
        </Button>
        </Popover>

    </>  
  )
}

export default Notification