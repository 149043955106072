import { LoadingOutlined } from '@ant-design/icons'
import { Card, Spin, Typography } from 'antd'
import React from 'react'
import { allLoader, Loader } from 'store'

// class GlobalLoader extends Component {
//   render(){
//     const [globalLoader, setGlobalLoader] = UseGlobalState('globalLoader')

//     return (
//       <CircularProgress />
//     )
//   }
// }

const GlobalLoader = () => {
  const loaders = allLoader()
  return (<div
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        padding: 20
      }}
    >
    {
      loaders && Object.keys(loaders).map(index => loaders[index] && (
          <Card key={index} >
            <Spin indicator={<LoadingOutlined/>}/>
            <Typography.Text> &nbsp;&nbsp; {loaders[index]}</Typography.Text>
          </Card>
      ))
    }
    </div>
  )
}
export default GlobalLoader