import React, { FC, ReactElement } from 'react'
import Header from '../components/Header'
import { Col, Layout as PageLayout, Row, Typography } from 'antd'
import Overlay from './Overlay'
import { ABOUT_APP, CONTACT_EMAIL, CONTACT_PHONE } from 'services/config'
import { useTranslation } from 'react-i18next'
import './main.scss'
export interface LayoutProps {
  children: ReactElement
}
const Layout: FC<LayoutProps> = (props) => {
  const { t } = useTranslation()
  return (
    <PageLayout>
      <Header />
      <PageLayout.Content className='gq-container'>
        <Overlay />
        {props.children}
      </PageLayout.Content>
      <PageLayout.Footer className='gq-container'>
        <Row>
          <Col xs={24}  xl={6}>
            <Typography.Text strong>
              {t(`About Gameqoo H5`)}
            </Typography.Text><br />
            <Typography.Paragraph>
              {ABOUT_APP}
            </Typography.Paragraph>
          </Col>
          <Col xs={24} xl={6}>
            <Typography.Text strong>
              {t(`Contact Us`)}
            </Typography.Text> <br />
            <Typography.Text>
              email: {CONTACT_EMAIL}
            </Typography.Text><br />
            <Typography.Text>
              {t('Phone')}: {CONTACT_PHONE}
            </Typography.Text>
          </Col>
        </Row>
      </PageLayout.Footer>
    </PageLayout>
  )
}
export default Layout