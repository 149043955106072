import { Button, Card, Input, Typography, Form, Spin, Modal } from 'antd'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Game, useGame } from 'services/game'
import Rating from 'react-rating-stars-component'
import { LoadingOutlined } from '@ant-design/icons'
import { Rule } from 'antd/lib/form'

export interface RatingFormProps {
  game: Game
  onRate?: Function
}
const FormRating: FC<RatingFormProps> = ({
  game,
  onRate
}) => {
  const gameService = useGame()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [ratingForm, setRatingForm] = useState({
    star: 5,
    message: ''
  })
  const [form] = Form.useForm()
  const ratingChanged = (star) => {
    setRatingForm({
      ...ratingForm,
      star
    })
  }
  const submitReview = () => {
    setLoading(true)
    gameService
      .submitReview(game.publisher.username, game.name, ratingForm.star, ratingForm.message)
      .then(() => onRate && onRate())
      .catch(e => {
        e && e.code === 412 && Modal.error({ title: t(`Error message`), content: t(`You have to play at least 1 minute to review this game`) })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={'rating-form'}>
      <Typography.Title level={4}>{t('Rate This Game')}</Typography.Title>
      <Typography.Text>{t(`type your suggestion`)}</Typography.Text>
      <Rating
        style={{
          margin: '0 auto'
        }}
        size={40}
        count={5}
        activeColor="#00875F"
        value={ratingForm.star}
        onChange={ratingChanged}
      />
      <Card>
        <Form
          form={form}
          onValuesChange={(changedValues, values) => {
            setRatingForm({
              ...ratingForm,
              ...changedValues
            })
          }}
        >
          <Typography.Text>{t(`Review`)}</Typography.Text>
          <Form.Item
            name="message"
            rules={[{ required: true, message: t(`Review required`) } as Rule]}
          >
            <Input.TextArea
              name={'message'} />
          </Form.Item>
          {
            !loading && (
              <Button
                onClick={submitReview} type="primary" style={{
                  marginTop: 10,
                  width: '100%'
                }}>{t(`Submit`)}</Button>
            )
          }
          {
            loading && (
              <Spin indicator={<LoadingOutlined size={24} />} />
            )
          }
        </Form>
      </Card>
    </div>
  )
}

export default FormRating