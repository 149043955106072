import React, { FC, useState } from 'react'
import BannerData from 'samples/banner'
import { Card, Carousel, Col, Image, Row, Typography } from 'antd'
import { useConfig } from 'services/config'

const { Title, Paragraph } = Typography
export interface BannerSource {
  uri: string
  type: "image" | "video"
}
export interface BannerData {
  action_link: string
  source: BannerSource
  description: string
  title: string
}
export interface BannerItemProps {
  item: BannerData
}

export const BannerItem: FC<BannerItemProps> = (props) => (
  <Row>
    <Col span={12}>
      <Image height="100%" src={props.item.source.uri as string} />
    </Col>
    <Col span={12} style={{
      padding: 20
    }}>
      <Title>{props.item.title}</Title>
      <Paragraph>{props.item.description}</Paragraph>
    </Col>
  </Row>
)

const Banner: FC = (props) => {
  const config = useConfig()
  const [banners, setBanners] = useState<BannerData[]>(config.BANNER_DATA())
  return (
    <>
      <Carousel style={{
        background: 'white'
      }} autoplay>
        {
          banners.map((v, i) => (
            <BannerItem key={`banner-${i}`} item={v} />
          ))
        }
      </Carousel>
    </>
  )
}

export default Banner