import { Button } from 'antd'
import Payment from 'components/Payment';
import Upoint from 'components/Upoint';
import React, { FC, useState } from 'react'
import { GAME_LAUNCHER_UPOINT_CLIENT_KEY } from 'services/config';
import { Game, useGame } from 'services/game';

export interface GamePaymentProps {
  game: Game
  onSuccess?: Function
}

export const GamePayment: FC<GamePaymentProps> = ({ game, onSuccess }) => {
  const [token, setToken] = useState('')
  const gameService = useGame()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [modalPaymentOpen, setModalPaymentOpen] = useState(false)
  const getPayment = () => {
    // if(paymentLoading) return

    // setPaymentLoading(true)
    // gameService
    // .generateToken(game)
    // .then( paymentToken => {
    //   setToken(paymentToken)
    // })
    // .catch((e) => {

    // })
    // .finally(() => {
    //   setPaymentLoading(false)
    // })
    setModalPaymentOpen(true)
    return 
  }
  return <>
    <Button loading={paymentLoading} onClick={getPayment}>Rp{game.extras.price}</Button>
    {/* <Upoint token={token} clientKey={GAME_LAUNCHER_UPOINT_CLIENT_KEY} onSuccess={onSuccess}/> */}
    <Payment param={{
      game_name: game.name, publisher_username: game.publisher.username
    }} open={modalPaymentOpen} onCloseButton={() => setModalPaymentOpen(false)} />
  </>
}

