
import { Empty } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Game, Rate, useGame } from 'services/game'
import { Loader, useGlobalState, useLoader } from 'store'
import ListItem from './ListItem'
import Pagination, { PaginationProps } from './Pagination'

export interface ListRatingProps {
  game: Game
}
export const RATE_REFRESH_GS = 'refresh-rate'
const RATES: Rate[] = []
const PAGINATION: PaginationProps = {
  page: 1,
  totalPage: 1,
  totalRows: 10,
  sizePerPage: 10
} as PaginationProps
const ListRating: FC<ListRatingProps> = ({
  game
}) => {
  const { t } = useTranslation()
  const [rateRefresh, setRateRefresh] = useGlobalState(RATE_REFRESH_GS, false)

  const [startReviewLoader, stopReviewLoader] = useLoader(RATE_REFRESH_GS)

  const [rates, setRates] = useState(RATES)
  const [loading, setLoading] = useState(false)
  const gameService = useGame()
  const [pagination, setPagination] = useState(PAGINATION)
  const componentDidMount = () => {
    fetchReviews()
  }
  const fetchReviews = () => {
    if (!game.name) return
    setLoading(true)
    startReviewLoader(t(`Fetching reviews`))
    gameService
      .fetchRates(game.publisher.username, game.name, pagination.page)
      .then(rates => {
        setRates(rates.data)
        setPagination({
          ...pagination,
          totalPage: rates.pagination.total_page as number,
          totalRows: rates.pagination.total_row as number
        })
      })
      .catch(e => {

      })
      .finally(() => {
        setRateRefresh(false)
        stopReviewLoader()
        setLoading(false)
      })
  }
  useEffect(componentDidMount, [game, pagination.page])
  useEffect(() => {
    if (!rateRefresh) return
    setPagination({
      ...pagination,
      page: 1
    })
    fetchReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateRefresh])
  useEffect(() => {
    if (loading)
      startReviewLoader(t(`Refreshing reviews`))
    else
      stopReviewLoader()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])
  const onPageClick = (page) => {
    setPagination({
      ...pagination,
      page: page
    })
  }
  return (
    <div style={{
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      paddingTop: 20,
    }}>
      {
        rates.length > 0 && <>
          {
            rates.map((v, i) =>
              <ListItem review={v} key={`rate-item-${i}`} />
            )
          }
          <Pagination {...pagination} style={{
            margin: 10
          }} onPageClick={onPageClick} />
        </>
      }
      {
        rates.length === 0 &&
        <Empty description={'No reviews'} />
      }
    </div>
  )
}

export default ListRating