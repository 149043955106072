import { AxiosRequestConfig } from "axios"
import { TOKEN, TOKEN_ST, UNAUTHORIZED_CODE, UserProfile } from "./openid"
import Request, { UNAUTH_CODE } from "./request"
import { useStorageWithGlobalState } from "./storage"
import qs from 'query-string'
import { to } from 'await-to-js'
import { useEffect, useState } from "react"
const GAME_LAUNCHER_GAME_BACKEND_URL = window._env_.GAME_LAUNCHER_GAME_BACKEND_URL
export const HOME_LOADER_KEY = 'home'
export interface Publisher extends UserProfile {
  username: string
}
export interface Player extends Publisher {
  useranme: string
}

export enum PageType {
  TopRated = 'top-rated', Popular = 'popular', Newest = 'newest', Owned = 'owned'
}
export interface RateAggs {
  star: number
  count: number
}

export interface Rate {
  player: Player
  rate: number
  review: string
  created_at: Date
}
export interface Game {
  name: string
  display_name: string
  publisher: Publisher
  tagline: string
  platforms: string[]
  categories: string[]
  modes: string[]
  minimum_age: number
  description: string
  tags: string[]
  pricing_type: string
  extras: any
  status: string
  icon_image: string
  header_image: string
  highlight_images: string[]
  videos: string[]
  my_rate: any
  rate_avg: number
  rate_agg: RateAggs[]
  rates: Rate[]
  total_reviews: number
  eligibility: boolean
}

export interface HomeData {
  name: string
  games: Game[]
  more: string
}

export const HOME_DATA = {} as HomeData

export interface Pagination {
  pages?: number
  total_row?: number
  rows?: number
  total_page?: number
}

export interface DataPaginated<T> {
  data: T
  pagination: Pagination
}

export interface GameSearchParams {
  categories?: string[]
  platforms?: string[]
  modes?: string[]
  pricing_type?: 'free' | 'freemium' | 'paid'
  keyword?: string
  minimum_age?: number
  pages?: number
  rows?: number
}

export const getGameIcon = (game: Game) => `${GAME_LAUNCHER_GAME_BACKEND_URL}/publishers/${game.publisher.username}/games/${game.name}/icon-image`

export const useGame = () => {
  const [token, setToken] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const cfg: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token && token.access_token}`
    }
  }
  const clientGame = new Request({
    baseURL: GAME_LAUNCHER_GAME_BACKEND_URL,
  })
  clientGame.failure(UNAUTH_CODE, () => {
    !token && setToken(undefined)
  })
  return ({
    async home(): Promise<HomeData[]> {
      const res = await clientGame.get(`/games/home`, cfg)
      return res.data
    },
    async list(path: PageType, pagination: Pagination): Promise<Game[]> {
      const param = qs.stringify(pagination)
      const res = await clientGame.get(`/games/${path}?${param}`, cfg)
      return res.data
    },
    async detail(publisher: string, name: string): Promise<Game> {
      const res = await clientGame.get(`/publishers/${publisher}/games/${name}`, cfg)
      return res.data
    },
    async search(param: GameSearchParams): Promise<Game[]> {
      const p = qs.stringify(this.buildSearch(param))
      const res = await clientGame.get(`/games?${p}`, cfg)
      return res.data

    },
    buildSearch(param: GameSearchParams): any {
      return param ? (() => {
        let p: any = {}
        for (const k in param) {
          const element = param[k];
          if (Array.isArray(element)) {
            p[k] = element.join(',')
          } else {
            p[k] = element
          }
        }
        return p
      })() : {}
    },
    async play_url(publisher: string, name: string, event?: string): Promise<string> {
      if (!token) return ''
      if (!token.id_token) return ''
      const param = {
        id_token: token ? token.id_token : undefined,
        event_name: event
      }
      return `${GAME_LAUNCHER_GAME_BACKEND_URL}/publishers/${publisher}/games/${name}/play/index.html?${qs.stringify(param)}`
    },
    async play(publisher: string, name: string): Promise<Game> {
      const res = await clientGame.get(`/publishers/${publisher}/games/${name}/play_url`, cfg)
      return res.data
    },
    async startPlay(publisher: string, name: string): Promise<number> {
      const res = await clientGame.post(`/publishers/${publisher}/games/${name}/plays`, {}, cfg)
      return res.data.play_id
    },
    async endPlay(publisher: string, name: string, playId: number): Promise<Game> {
      const res = await clientGame.post(`/publishers/${publisher}/games/${name}/plays/${playId}`, {}, cfg)
      return res.data.play_id
    },
    async fetchRates(publisher: string, name: string, pages: number): Promise<DataPaginated<Rate[]>> {
      const param = qs.stringify({
        pages,
        rows: 10
      })
      const res = await clientGame.get(`/publishers/${publisher}/games/${name}/rates?${param}`, cfg)
      return res
    },
    async submitReview(publisher: string, name: string, rate: number, review: string): Promise<void> {
      await clientGame.post(`/publishers/${publisher}/games/${name}/rates`, {
        rate,
        review
      }, cfg)
    },
    getIcon(game: Game): string {
      return `${GAME_LAUNCHER_GAME_BACKEND_URL}/publishers/${game.publisher.username}/games/${game.name}/icon-image`
    },
    async generateToken(game: Game): Promise<string> {
      const r = await clientGame.post(`/publishers/${game.publisher.username}/games/${game.name}/purchase/browser`, {}, cfg)
      return r.data.token
    },
    getRank(game: Game): Promise<{data: string}> {
      return clientGame.get(`/publishers/${game.publisher.username}/games/${game.name}/rank`, cfg)
    }
  })
}

export const useCategories = () => {
  const clientGame = new Request({
    baseURL: GAME_LAUNCHER_GAME_BACKEND_URL,
  })
  const [categories, setCategories] = useState<string[]>([])
  const get = async () => {
    const [, r] = await to<any>(clientGame.get(`/games/categories`))
    setCategories(r && r.data || [])
  }
  useEffect(() => { get() }, [])
  return { categories, get }
}