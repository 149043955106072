import { Card, Col, Layout, Menu, Row, Typography } from 'antd'
import { PROFILE_INIT, USER_PROFILE_GS } from 'components/Account/Account'
import Profile from 'components/Account/Profile'
import Coin from 'components/Coin'
import PurchasedGame from 'components/PurchasedGame'
import Rewards from 'components/Rewards'
import React, { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { TOKEN_ST } from 'services/openid'
import { useStorageWithGlobalState } from 'services/storage'
import { useGlobalState } from 'store'
import Avatar from '../components/Account/Avatar'

const AccountPage: FC = () => {
  const [token, setToken] = useStorageWithGlobalState(TOKEN_ST, '')
  const { page: pageParam } = useParams<{page: string}>()
  const [page, setPage] = useState(pageParam || 'profile')
  const [userProfile] = useGlobalState(USER_PROFILE_GS, PROFILE_INIT)

  const _logout = () => {
    setToken(undefined)
    window.location.assign(window.origin)
  }

  useEffect(() => {
    pageParam ?  setPage(pageParam) : setPage('profile')
  }, [pageParam])
  const getUsername = () => {
    if(!userProfile.avatar) return
    const av = userProfile.avatar.split('/')
    return av[av.length - 1]
  }
  return <>
      <Layout.Content>
        <Card>
          <Row>
            <Col>
              <Avatar/>
            </Col>
            <Col style={{padding: 10}}>
              <Typography.Title style={{ marginBottom: 0}}>{userProfile.fullname}</Typography.Title>
              <Typography.Text>ID: {getUsername () || userProfile.preferred_username || userProfile.email}</Typography.Text>
              <Coin/>
            </Col>
          </Row>
        </Card>
        <br/>
        <Row style={{marginBottom: 50}}>
          <Col xs={0} xl={8} style={{paddingRight: 20}}>
            <Card style={{padding: 0}} >
              <Menu>
                <Menu.Item isSelected={page === 'profile'}>
                  <Link to={`/accounts/profile`}>Profile</Link>
                </Menu.Item>
                <Menu.Item isSelected={page === 'games'}>
                  <Link to={`/accounts/games`}>Purchased Games</Link>
                </Menu.Item>
                <Menu.Item isSelected={page === 'coins'}>
                  <Link to={`/accounts/coins`}>Poin</Link>
                </Menu.Item>
                <Menu.Item onClick={_logout}>
                  Logout
                </Menu.Item>
              </Menu>
            </Card>
          </Col>
          <Col xl={0} xs={24} style={{paddingRight: 20}}>
              <Menu  mode="horizontal">
                <Menu.Item isSelected={page === 'profile'}>
                  <Link to={`/accounts/profile`}>Profile</Link>
                </Menu.Item>
                <Menu.Item isSelected={page === 'games'}>
                  <Link to={`/accounts/games`}>Purchased Games</Link>
                </Menu.Item>
                <Menu.Item isSelected={page === 'coins'}>
                  <Link to={`/accounts/coins`}>Poin</Link>
                </Menu.Item>
                <Menu.Item onClick={_logout}>
                  Logout
                </Menu.Item>
              </Menu>
          </Col>
          <Col xl={16} xs={24}>
              { page === 'profile' && <Profile/>}
              { page === 'coins' && <Rewards/>}
              { page === 'games' && <PurchasedGame/>}
          </Col>
        </Row>
      </Layout.Content>
  </>
}

export default AccountPage