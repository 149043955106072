import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { Game } from 'services/game'
import Card from '../Card'

export interface GameSetProps {
  games: Game[]
}

export const HorizontalSet: FC<GameSetProps> = ({ games }) => {
  return (
    <Row>
      {
        games &&
        games.map((v, i) => i < 8 && (
          <Col sm={12}  xs={24} xl={3} xxl={3} style={{ padding: 10 }}>
            <Card game={v} key={`set-game-${i}`} />
          </Col>
        ))
      }
    </Row>
  )
}

const Set: FC<GameSetProps> = ({ games }) => {
  return (
    <Row>
      {
        games &&
        games.map((v, i) => (
          <Col sm={12}  xs={24} xl={3} xxl={3} style={{ padding: 10 }}>
            <Card game={v} key={`set-game-${i}`} />
          </Col>
        ))
      }
    </Row>
  )
}

export default Set
