import { Card, Col, Row, Spin, Typography } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Score } from 'services/event'
import usePlayer from 'services/player'

export interface TournamentLeaderboardProps {
  scores: Score[]
}
export interface TournamentLeaderboardItemProps {
  score: Score,
  no: number
}
export const TournamentLeaderboardItem: FC<TournamentLeaderboardItemProps> = ({ score, no }) => {
  const {player} = usePlayer(score.player_username)

  return (
    <Card className="leaderboard-card center-content">
      <Row>
        <Col xs={8}>
          <Typography.Text strong>
            {no}
          </Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text strong>
            {player ? player.fullname : <Spin/>}
            {score.player_name && `: ${score.player_name}`}
          </Typography.Text>
        </Col>
        <Col xs={8}>
          <Typography.Text strong>
            {score.score}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}

const TournamentLeaderboard: FC<TournamentLeaderboardProps> = ({ scores }) => {
  const { t } = useTranslation()
  return (
    <>
      <Card className="leaderboard-card leaderboard-header">
        <Row>
          <Col xs={8}>
            <Typography.Text strong>
              {t(`Rank`)}
            </Typography.Text>
          </Col>
          <Col xs={8}>
            <Typography.Text strong>
              {t(`Player`)}
            </Typography.Text>
          </Col>
          <Col xs={8}>
            <Typography.Text strong>
              {t(`Score`)}
            </Typography.Text>
          </Col>
        </Row>

      </Card>
      {
        scores.length === 0 && <Typography.Text strong>{t(`No data`)}</Typography.Text>
      }
      {
        scores.map((s, i) =>
          <TournamentLeaderboardItem score={s} no={i + 1} key={`score-${i}`} />
        )
      }
    </>
  )
}

export default TournamentLeaderboard