
import { Button, Col, Row, Typography } from 'antd'
import TournamentSet from 'components/Tournament/Set'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Event, EVENT_LOADER_KEY, useEvent } from 'services/event'
import { Loader, useLoader } from 'store'
const rows = 10
const EVENTS: Event[] = []
const Tournament: FC<{}> = () => {
  const eventService = useEvent()
  const { t } = useTranslation()
  const [startEventLoader, stopEventLoader] = useLoader(EVENT_LOADER_KEY)
  const [events, setEvents] = useState(EVENTS)
  const [isMore, setIsMore] = useState(true)
  const [page, setPage] = useState(1)
  const fetchEvents = () => {
    startEventLoader(t(`Fetching events`))
    eventService.getEvents(page, rows).then(e => {
      setEvents([...events, ...e.data])
      if (e.data.length < rows) setIsMore(false)
    })
      .catch(() => {
        setIsMore(false)
      })
      .finally(() => stopEventLoader())
  }
  useEffect(fetchEvents, [page])
  return (
    <>
      <Row>
        <Col xs={24}>
          <Typography.Title>
            {t(`
              All available tournament
            `)}
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <TournamentSet events={events} />
          {
            isMore && <Button onClick={() => setPage(page + 1)}>{t(`Load more`)}</Button>
          }

        </Col>
      </Row>
    </>
    
  )
}
export default Tournament