import { Layout, Typography } from 'antd'
import Banner from 'components/Banner'
import { HorizontalSet } from 'components/Games/Set/Set'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { HomeSamples } from 'samples/game'
import { HOME_LOADER_KEY, useGame } from 'services/game'
import { TOKEN_ST } from 'services/openid'
import { useGlobalState, useLoader } from 'store'
const { Text, Link } = Typography
const Home: FC<RouteComponentProps> = (props) => {
  const gameService = useGame()
  const [homeData, setHomeData] = useState(HomeSamples)
  const [token] = useGlobalState(TOKEN_ST, false)
  const [startHomeLoader, stopHomeLoader] = useLoader(HOME_LOADER_KEY)
  const { t } = useTranslation()

  const fetchHome = () => {
    startHomeLoader( t(`Fetching home data`))
    gameService
      .home()
      .then(homeData => setHomeData(homeData)).catch(e => {
      }).finally(() => {
        stopHomeLoader()
      })
  }
  const componentDidMount = () => {
    fetchHome()
  }
  useEffect(componentDidMount, [token])
  useEffect(componentDidMount, [])
  return (
    <Layout.Content>
      <Banner />
      {
        homeData &&
        homeData.map((v) => (
          <div style={{ marginTop: 10, marginBottom: 10 }} key={`section-${v.name.split(' ').join('-').toLowerCase()}`}>
            <Text style={{ fontWeight: 'bold' }}>{t(v.name as string)}</Text>
            <Link style={{
              float: 'right'
            }} href={`/home/${v.more.split('/')[2]}`}>{t('Show all')}</Link>
            <HorizontalSet games={v.games} />
          </div>
        ))
      }
    </Layout.Content>
  )
}

export default Home