import { Button, Card, Image, Row } from 'antd'
import React, { FC, useRef, useState } from 'react'
import ItemsCarousel from 'react-items-carousel'

import './images.scss'
export interface GameImageItemProps {
  uri: string
}
export const GameImageItem: FC<GameImageItemProps> = ({
  uri
}) => {
  return (
    <Card className="game-card" style={{
      width: 150
    }}>
      <Image width={150} height={150} src={uri as string} />
    </Card>

  )
}
export interface GameImageAssetProps {
  highlights: string[]
}
const GameImageAsset: FC<GameImageAssetProps> = ({
  highlights
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  return (
    <ItemsCarousel
      requestToChangeActive={setActiveItemIndex}
      activeItemIndex={activeItemIndex}
      numberOfCards={3}
      gutter={20}
      leftChevron={<Button shape='circle-outline'>{'<'}</Button>}
      rightChevron={<Button shape='circle-outline'>{'>'}</Button>}
      outsideChevron
      chevronWidth={chevronWidth}
    >
      {
        highlights && highlights.map((v, i) => (
          <GameImageItem key={`asset-image-${i}`} uri={v} />
        ))
      }
    </ItemsCarousel>
  )
}

export default GameImageAsset