import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Col, Row, Typography , Input} from 'antd'
import SampleCategories from 'samples/categories'
import { useDeepEffect, useGlobalState, useQuery } from 'store'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { useCategories } from 'services/game'
export const SELECTED_CATEGORIES_GS = 'selectedCategories'
export const CATEGORIES_GS = 'categories'
export const SELECTED_MODE_GS = 'selectedMode'
export enum MODES {
  ALL = 'all',
  SINGLE_PLAYER = 'singleplayer',
  MULTI_PLAYER = 'multiplayer'
}
const { Text } = Typography
const { Search } = Input
export const SELECTED_CATEGORIES: string[] = [MODES.ALL]

export const SELECTED_MODES: string[] = [MODES.ALL]



const Categories: FC<{}> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const queries = useQuery()
  const { categories } = useCategories()
  const [selectedModes, setSelectedModes] = useGlobalState(SELECTED_MODE_GS, SELECTED_MODES)
  const [selectedCategories, setSelectedCategories] = useGlobalState(SELECTED_CATEGORIES_GS, SELECTED_CATEGORIES)

  const removeAllIdx = (states: string[], dispatcher: Dispatch<string[]>) => {
    const allIdx = states.indexOf(MODES.ALL)
    if (allIdx !== -1) remove(states, dispatcher)(allIdx)
  }
  const remove = (states: string[], dispatcher: Dispatch<string[]>) => (idx: number) => {
    states.splice(idx, 1)
    return dispatcher([
      ...states
    ])
  }
  const check = (states: string[], dispatcher: Dispatch<string[]>) => (v: string) => {
    const idx = states.indexOf(v)
    if (idx === -1) {
      if (v === MODES.ALL) {
        return dispatcher([
          MODES.ALL
        ])
      }
      removeAllIdx(states, dispatcher)
      return dispatcher([
        ...states, v
      ])
    }
    remove(states, dispatcher)(idx)
  }

  const selectCategory = (v) => {
    let idx = selectedCategories.indexOf(v)
    let c = [...selectedCategories]
    if (idx > -1) {
      c.splice(idx, 1)
    }
    else {
      if (v === MODES.ALL) c = [MODES.ALL]
      else {
        if (v !== MODES.ALL) {
          const idAll = c.indexOf(MODES.ALL)
          if (idAll > -1) {
            c.splice(idAll, 1)
          }
        }
        c.push(v)
      }
    }
    setSelectedCategories([
      ...c
    ])
  }

  const selectModes = (v) => {
    let idx = selectedModes.indexOf(v)
    let c = [...selectedModes]
    if (idx > -1) {
      c.splice(idx, 1)
    }
    else {
      if (v === MODES.ALL) c = [MODES.ALL]
      else {
        if (v !== MODES.ALL) {
          const idAll = c.indexOf(MODES.ALL)
          if (idAll > -1) {
            c.splice(idAll, 1)
          }
        }
        c.push(v)
      }
    }
    setSelectedModes([
      ...c
    ])
  }

  useDeepEffect(() => {
    let params = {
      modes: queries.get('modes'),
      search: queries.get('search'),
      categories: selectedCategories.length === 0 ? null : selectedCategories[0] === 'all' ? null : selectedCategories.join(',')
    }
    history.push({
      pathname: `/search`,
      search: `?${qs.stringify(params)}`
    })
  }, [selectedCategories])

  useDeepEffect(() => {
    let params = {
      modes: selectedModes.length === 0 ? null : selectedModes[0] === 'all' ? null : selectedModes.join(','),
      categories: queries.get('categories'),
      search: queries.get('search'),
      
    }

    history.push({
      pathname: `/search`,
      search: `?${qs.stringify(params)}`
    })

  }, [selectedModes])

  useEffect(() => {
    setSelectedModes(queries.get('modes') ? queries.get('modes')?.split(',') as [] : selectedModes)
    setSelectedCategories(queries.get('categories') ? queries.get('categories')?.split(',') as [] : selectedCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{
      padding: 20,
      background: '#fff',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    }}>
      <Row gutter={16} style={{
      }}>
        <Col>
          <Text type="secondary">{t('mode')}</Text><br />
          <Checkbox checked={selectedModes.indexOf(MODES.ALL) !== -1} onClick={() => selectModes(MODES.ALL)}>{t('all')}</Checkbox><br />
          <Checkbox checked={selectedModes.indexOf(MODES.MULTI_PLAYER) !== -1} onClick={() => selectModes(MODES.MULTI_PLAYER)} >{t('multiPlayer')}</Checkbox><br />
          <Checkbox checked={selectedModes.indexOf(MODES.SINGLE_PLAYER) !== -1} onClick={() => selectModes(MODES.SINGLE_PLAYER)} >{t('singlePlayer')}</Checkbox>
        </Col>
        <Col>
          <Text type="secondary">{t('genres')}</Text><br />
          <ul style={{
            columns: 2,
            textDecoration: 'none',
            paddingLeft: 0
          }}>
            <li style={{
              listStyleType: 'none'
            }}><Checkbox value={MODES.ALL} onClick={() => selectCategory(MODES.ALL)} checked={selectedCategories.indexOf(MODES.ALL) !== -1} key={'x1'}>{t('allGenres')}</Checkbox></li>
            {
              categories.map((v, i) => (
                <li style={{
                  listStyleType: 'none'
                }} key={`cat-${i}`}><Checkbox style={{ textTransform: 'capitalize' }} value={v} onClick={() => selectCategory(v)} checked={selectedCategories.indexOf(v) !== -1} key={i}>{t(v, v.split(`-`).join(` `))}</Checkbox></li>
              ))
            }
          </ul>
        </Col>
      </Row>
    </div>
  )
}

const C = (<Categories />)
export default C