import { useState } from "react";
import { useLoader } from "store";
import { useConfig } from "./config";
import { TOKEN, TOKEN_ST } from "./openid";
import Request from "./request";
import { useStorageWithGlobalState } from "./storage";
import qs from 'query-string'
import useCoin from "./coin";
export interface Reward {
  id: string
  name: string
  image: string
  price: number
  description: string
  type: 'coupon'
  extras: any
  expire: Date
}

export interface Paginated<T = any> {
  data: T[]
  total_row: number
}
export interface RewardLoading {
  insertLoading: boolean
  updateLoading: boolean
  deleteLoading: boolean
  fetchLoading: boolean
}
const useReward = () => {  
  const [listReward, setListReward] = useState<Reward[]>([])
  const {get: getCoin} = useCoin()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [token] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const config = useConfig()

  const [
    [fetchLoadingStart, fetchLoadingFinish],
    [redeemLoadingStart, redeemLoadingFinish]
  ] = [
    useLoader('fetch-rewards'),
    useLoader('redeem-rewards')
  ]

  const rewardClients = new Request({
    baseURL: config.BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${token.access_token}`
    }
  })

  function fetch(cb?: Function) {
    fetchLoadingStart(`Fetching rewards`)
    rewardClients
    .get(`/rewards?${qs.stringify({ pages: page, rows: size })}`)
    .then(r => {
      setListReward(r.data)
      cb && cb()
    })
    .catch(e => {

    })
    .finally(() => fetchLoadingFinish())
  }
  
  function redeem(id: string, cb?: Function) {
    redeemLoadingStart(`Fetching rewards`)
    rewardClients
    .post(`/rewards/${id}/redeem`)
    .then(r => {
      getCoin()
      cb && cb()
    })
    .catch(e => {

    })
    .finally(() => redeemLoadingFinish())
  }

  return {
    listReward,
    page, setPage,
    size, setSize,
    setListReward,
    fetch,
    redeem
  }
} 

export default useReward