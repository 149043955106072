import { Button, Layout, Empty, Typography } from 'antd'
import Set from 'components/Games/Set/Set'
import { SELECTED_CATEGORIES_GS, SELECTED_CATEGORIES, SELECTED_MODE_GS } from 'components/Header/Categories'
import { GAME_SEARCH_GS } from 'components/Header/Header'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { GameSetSample } from 'samples/game'
import { Game, HOME_LOADER_KEY, PageType, useGame } from 'services/game'
import { useGlobalState, useLoader, useQuery } from 'store'
const LOADER_GAMES = 'search-games'
const GAMES: Game[] = GameSetSample
const rows = 48
const Home: FC<RouteComponentProps> = (props) => {
  const queries = useQuery()
  const [gameSearch] = useGlobalState(GAME_SEARCH_GS, '')
  const { page: pageFromHome } = useParams<{page: string}>()
  const [games, setGames] = useState(GAMES)
  const [param, setParam] = useState({
    categories: queries.get('categories') ? queries.get('categories')?.split(',') : [] as string[],
    modes: queries.get('modes') ? queries.get('modes')?.split(',') : [] as string[],
    keyword: ''
  })
  const [startGamesLoader, stopGamesLoader, loaderGames] = useLoader(LOADER_GAMES)
  const { t } = useTranslation()
  const componentDidMount = () => {
  }

  useEffect(componentDidMount, [])
  const [selectedCategories] = useGlobalState(SELECTED_CATEGORIES_GS, SELECTED_CATEGORIES)
  const [selectedModes] = useGlobalState(SELECTED_MODE_GS, SELECTED_CATEGORIES)
  const gameService = useGame()
  const [isMore, setIsMore] = useState(true)
  const [page, setPage] = useState(1)
  useEffect(() => {
    setParam({
      ...param,
      categories: selectedCategories.length === 0 ? [] : selectedCategories[0] === 'all' ? [] : selectedCategories
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories])
  useEffect(() => {
    setParam({
      ...param,
      modes: selectedModes.length === 0 ? [] : selectedModes[0] === 'all' ? [] : selectedModes
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModes])
  useEffect(() => {
    setParam({
      ...param,
      keyword: gameSearch
    })
  }, [gameSearch])

  const searchGames = () => {
    if (loaderGames) return
    startGamesLoader(`Fetching games`)
    const p = pageFromHome ? gameService.list(pageFromHome as PageType, {
      pages: page,
      rows: rows
    }) : gameService.search({
      categories: param.categories,
      modes: param.modes,
      keyword: param.keyword,
      pages: page,
      rows: rows
    })
    
    p.then(g => {
      setGames(page > 1 ? [...games, ...g] : g)
      if (g.length < rows) setIsMore(false)
    })
      .catch(() => {

      }).finally(() => stopGamesLoader())
  }

  useEffect(() => {
    setPage(1)
    searchGames()
  }, [param])
  useEffect(searchGames, [page])
  return (
    <Layout.Content>
      <>
        <Set games={games} />
        {
          isMore && <Button onClick={() => setPage(page + 1)}>{t(`Load more`)}</Button>
        }
        { (!games || games.length === 0 ) && <Empty/> }
      </>
    </Layout.Content>
  )
}

export default Home