import { CLIENT_ID, CLIENT_SECRET, OPENID_HOST, OPENID_SCOPE } from './config'
import Request from './request'
import qs from 'query-string'
import { getItem, useStorageWithGlobalState } from './storage'
export const UNAUTHORIZED_CODE = 401
export interface Token {
  access_token: string;
  refresh_token?: string;
  token_type: "Bearer";
  expires_in: number;
  id_token: string;
  generated: number
}

export interface Address {
  locality: string
  region: string
  postal_code: string
  country: string
}

export interface UserProfile {
  fullname: string
  birthdate: string
  family_name: string
  gender: string
  given_name: string
  locale: string
  middle_name: string
  name: string
  nickname: string
  phone_number: string
  phone_number_verified: Boolean
  email: string
  email_verified: Boolean
  picture: string
  preferred_username: string
  website: string
  zoneinfo: string
  address: Address
  avatar: string
}
export const TOKEN_ST = 'token'
export const TOKEN = {} as Token
export const LOGGED_IN_ST = 'logged-in'
export const TOKEN_EXPIRE_TIME = 3599
export const getAvatar = (username: string): string => {
  return `${OPENID_HOST}/backend/user/avatar/${username}`
}
export default () => {
  const client = new Request({
    baseURL: OPENID_HOST
  })
  const [token, setToken] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  return {
    async facebook(access_token: string): Promise<Token> {
      const token = await client.post(`/backend/social/facebook/login`, {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        scope: OPENID_SCOPE,
        access_token,
        audience: 'game-launcher'
      })
      const res = {
        ...token,
        generated: (new Date()).getTime() / 1000
      }
      setToken(res)
      return res
    },
    async google(id_token: string): Promise<Token> {
      setToken(undefined)
      const token = await client.post(`/backend/social/google/login`, {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        scope: OPENID_SCOPE,
        id_token,
        audience: 'game-launcher'
      })
      const res = {
        ...token,
        generated: (new Date()).getTime() / 1000
      }
      setToken(res)
      return res
    },
    refreshToken(token: Token): Promise<Token> {
      return client.post(`/oauth2/token`,
        qs.stringify({
          grant_type: 'refresh_token',
          refresh_token: token.refresh_token
        }),
        {
          auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET
          },
          headers: {
            "Access-Control-Allow-Origin": '*'
          }
        }
      )
    },
    async getProfile(token?: Token): Promise<UserProfile> {
      const tokenData = token || getItem(TOKEN_ST)
      const t = await this.checkToken(tokenData)
      if (t.access_token !== tokenData.access_token)
        setToken({
          ...t,
          generated: (new Date()).getTime() / 1000
        })
      const res = await client.get(`/api/profile`, {
        headers: {
          Authorization: `Bearer ${t.access_token}`
        }
      })
      return res
    },
    async editProfile(profile: UserProfile, token?: Token): Promise<UserProfile> {
      const tokenData = token || getItem(TOKEN_ST)
      const t = await this.checkToken(tokenData)
      if (t.access_token !== tokenData.access_token)
        setToken({
          ...t,
          generated: (new Date()).getTime() / 1000
        })
      const res = await client.put(`/api/profile`, profile, {
        headers: {
          Authorization: `Bearer ${t.access_token}`
        }
      })
      return res
    },
    async changeAvatar(avatar: File, token?: Token): Promise<UserProfile> {
      const tokenData = token || getItem(TOKEN_ST)
      const t = await this.checkToken(tokenData)
      if (t.access_token !== tokenData.access_token)
        setToken({
          ...t,
          generated: (new Date()).getTime() / 1000
        })

      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "file", 
        avatar, 
        avatar.name 
      ); 
     
      const res = await client.post(`/api/avatar`, formData, {
        headers: {
          Authorization: `Bearer ${t.access_token}`
        }
      })
      return res
    },
    async checkToken(t: Token): Promise<Token> {
      try {
        // const now = (new Date()).getTime() / 1000
        const now = ((new Date()).getTime() / 1000)
        
        if (now - TOKEN_EXPIRE_TIME >= t.generated) {
          return await this.refreshToken(t)
        }
        return t
      } catch (e) {
        // console.log('disini',e)
        // setToken(undefined)
        throw e
      }
    }
  }
}
