import { Loader, useGlobalState, useLoader } from "store"
import { useConfig } from "./config"
import { TOKEN, TOKEN_ST } from "./openid"
import Request from "./request"
import { useStorageWithGlobalState } from "./storage"

const useCoin = () => {
  const [coin, setCoin] = useGlobalState(`COIN-GS`, 0)
  const [startFetchLoader, dismissFetchLoader] = useLoader(`fetch-coin-loader`)
  const [token] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const config = useConfig()
  const client = new Request({
    baseURL: config.BACKEND_HOST,
    headers: {
      Authorization: `Bearer ${token.access_token}`
    }
  })
  function get() {
    startFetchLoader(`Fetching coin`)
    client
    .get(`/coin`)
    .then(r => {
      setCoin(r.data.amount)
    })
    .catch(() => {

    })
    .finally(() => {
      dismissFetchLoader()
    })
  }
  return { 
    coin, 
    setCoin,
    get
  }
}

export default useCoin