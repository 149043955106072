import React, { FC } from 'react'
import {
  BrowserRouter, Switch, Redirect
} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import Home from '../pages/Home'
import Search from '../pages/GameSearch'
import Tournament from '../pages/Tournament'
import TournamentDetail, { params as tournamentDetailParam } from '../pages/TournamentDetail'
import Layout from '../layout/Main'
import GameDetail, { params as gameDetailParam } from 'pages/GameDetail'
import AccountPage from 'pages/Account'


const Routes: FC<{}> = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/home"
        />
        <RouteWithLayout
          component={Search}
          layout={Layout}
          path="/home/:page"
        />
        <RouteWithLayout
          component={Home}
          layout={Layout}
          path="/home"
        />
        <RouteWithLayout
          component={Search}
          layout={Layout}
          path="/search"
        />
        <RouteWithLayout
          component={Tournament}
          layout={Layout}
          path="/tournament"
        />
        <RouteWithLayout
          component={TournamentDetail}
          layout={Layout}
          path={`/publishers/:${gameDetailParam.publisher}/games/:${gameDetailParam.name}/events/:${tournamentDetailParam.event_name}`}
        />
        <RouteWithLayout
          component={AccountPage}
          layout={Layout}
          path={`/accounts/:page`}
        />
        <RouteWithLayout
          component={AccountPage}
          layout={Layout}
          path={`/accounts`}
        />
        <RouteWithLayout
          component={GameDetail}
          layout={Layout}
          path={`/publishers/:${gameDetailParam.publisher}/games/:${gameDetailParam.name}`}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes