import React, { createContext, useReducer, useContext, ReactElement, Dispatch, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { InitialStates, DispatchState } from './constants'
import isEqual from 'lodash/isEqual'
const GlobalContext = createContext(InitialStates)
const DispatchContext = createContext(DispatchState);

export const GLOBAL_LOADER = 'globalLoader'
export interface GlobalStateProviderProps {
  children: ReactElement
}
export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useDeepEffect = (func: Function, dependencies: Array<Object>) => {
  const isFirst = useRef(true)
  const prevDeps = useRef(dependencies)

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, dependencies[index]))
    if(isFirst.current || !isSame){
      func()
    }
    isFirst.current = false
    prevDeps.current = dependencies
  }, dependencies)
}
export const GlobalStateProvider = (props: GlobalStateProviderProps) => {
  const { children } = props
  const [state, dispatch] = useReducer(
    (state: any, newValue: any) => {
      return ({ ...state, ...newValue })
    },
    InitialStates
  );
  return (
    <GlobalContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </GlobalContext.Provider>
  );
};


export function useGlobalState<Type>(k: string, v?: Type): [Type, Dispatch<Type>] {
  const [state, dispatch] = [
    useContext(GlobalContext),
    useContext(DispatchContext)
  ]
  const setState = (key: string, value: Type) => {
    let v = {}
    v[key] = value
    dispatch && dispatch({
      ...state, ...v
    })
  }
  const val: Type = state[k] || v
  const [localState, setLocalState] = useState(val)
  useDeepEffect(() => {
    setState(k, localState)
  }, [localState])

  // return [
  //   val,
  //   (v: Type) => {
  //     setState(k, v)
  //   }
  // ]
  return [
    val,
    setLocalState
  ]
};

export interface StartLoader {
  (m: string)
}
export interface StopLoader {
  ()
}
export const useLoader = (key): [StartLoader, StopLoader, string] => {
  const [l, f] = useGlobalState(`loader-${key}`, '')
  const startLoader = (m: string) => f(m)
  const stopLoader = () => setTimeout(() => f(''), 150)
  useEffect(() => {
    return () => f('')
  }, [])
  return [startLoader, stopLoader, l]
}

export const allLoader = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const states = useContext(GlobalContext)
  const loaders:string[] = []
  for (const k in states) {
    if(k.indexOf('loader')!== -1){
      loaders.push(states[k] as string)
    }
  }
  return loaders
}

export const Loader = () => {
  let [globalLoader, setGlobalLoader] = useGlobalState(GLOBAL_LOADER, {})

  const startLoader = (key: string, message: string) => {
    // let loader: any = globalLoader || {}
    // loader[`${key}-loader`] = message
    // setGlobalLoader(loader)
  }
  const finishLoader = (key: string) => {
    // let loader: any = globalLoader || {}
    // loader[`${key}-loader`] = null
    // setTimeout(() => setGlobalLoader(loader), 2000)
  }
  return { startLoader, finishLoader, globalLoader }
}
