import { Card, Image, Typography } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Game, getGameIcon } from 'services/game'
import { Link } from 'react-router-dom'
import './card.scss'
const { Text } = Typography
export interface CardGameProps {
  game: Game,
}

const CardGame: FC<CardGameProps> = ({ game }) => {
  const imageRef = useRef(null)
  const [height, setHeight] = useState('0')
  const getImageHeight = () => {
    imageRef.current && setHeight((imageRef.current as any).offsetWidth + 'px')
  }
  useEffect(getImageHeight, [imageRef.current])
  return (
    <Link to={`/publishers/${game.publisher.username}/games/${game.name}`}>
      <div ref={imageRef} style={{
        width: '100%'
      }}>
        <Card
          className="game-card" style={{
            width: '100%',
          }}>
          <Image
            style={{
              width: '100%',
            }}
            height={height}
            width={height}
            src={game.icon_image as string || getGameIcon(game)}
          />
          <Text style={{
            fontWeight: 'bold',
            alignContent: 'center'
          }}>{game.display_name}</Text>
          <Text style={{
            alignContent: 'center'
          }}>{game.tagline}</Text>
        </Card>
      </div>
    </Link>
  )
}

export default CardGame