import { Col, Empty, Row, Typography } from 'antd'
import { CardGame } from 'components/Games'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Game, PageType, useGame } from 'services/game'
import { useLoader } from 'store'
const PurchasedGame: FC = () => {
  const { 
    list
  } = useGame()
  const [start, stop] = useLoader(`my-game-loading`)
  const [games, setGames] = useState<Game[]>([])
  const {t} = useTranslation()
  
  const fetchGame = () => {
    start(`Fetching my games`)
    list(PageType.Owned, {
      pages: 1,
      rows: 100
    }).then(_games => {
      setGames(_games)
    }).catch(e => {

    }).finally(() => {
      stop()
    })
  }
  useEffect(() => {
    fetchGame()
  }, [])
  return <>
    <Typography.Title level={4}>{t(`Purchased Game`)}</Typography.Title>
    <Row>
      {
        games.map((r, i) => (<>
          <Col xs={24} xl={6} style={{padding: 20}} key={`item-reward-${i}`}>
            <CardGame game={r}/>
          </Col>
          </>
        ))
      }
      {
        games.length === 0 && <Empty/>
      }
    </Row>
  </>
} 

export default PurchasedGame