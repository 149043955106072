import { Button, Card, Image, Row } from 'antd'
import React, { FC, useRef, useState } from 'react'
import Iframe from 'react-iframe'
import ItemsCarousel from 'react-items-carousel'
import ReactPlayer from 'react-player'
import './images.scss'
export interface GameVideoItemProps {
  uri: string
}
export const GameVideoItem: FC<GameVideoItemProps> = ({
  uri
}) => {
  return (
    <Card className="game-card" style={{
      width: '100%',
      padding: 10,
      height: 200
    }}>
      <ReactPlayer height={180} width={'100%'} url={uri as string} />

    </Card>

  )
}
export interface GameVideoAssetProps {
  videos: string[]
}
const GameVideoAsset: FC<GameVideoAssetProps> = ({
  videos
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;

  return (
    <ItemsCarousel
      requestToChangeActive={setActiveItemIndex}
      activeItemIndex={activeItemIndex}
      numberOfCards={1}
      gutter={20}
      leftChevron={<Button shape='circle-outline'>{'<'}</Button>}
      rightChevron={<Button shape='circle-outline'>{'>'}</Button>}
      outsideChevron
      chevronWidth={chevronWidth}
    >
      {
        videos && videos.map((v, i) => (
          <GameVideoItem key={`video${i}`} uri={v} />
        ))
      }
    </ItemsCarousel>
  )
}

export default GameVideoAsset