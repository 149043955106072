import { Button } from 'antd'
import Payment from 'components/Payment';
import Upoint from 'components/Upoint';
import React, { FC, useState } from 'react'
import { GAME_LAUNCHER_UPOINT_CLIENT_KEY } from 'services/config';
import { Event, useEvent } from 'services/event';

export interface TournamentPaymentProps {
  event: Event
  onSuccess?: Function
}

export const TournamentPayment: FC<TournamentPaymentProps> = ({ event, onSuccess }) => {
  const [token, setToken] = useState('')
  const eventService = useEvent()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [modalPaymentOpen, setModalPaymentOpen] = useState(false)

  const getPayment = () => {
    // if(paymentLoading) return
    // setPaymentLoading(true)
    // eventService
    // .generateTokenPayment(event)
    // .then( paymentToken => {
    //   setToken(paymentToken)
    // })
    // .catch((e) => {

    // })
    // .finally(() => {
    //   setPaymentLoading(false)
    // })
    setModalPaymentOpen(true)
    return 
  }
  return <>
    <Button loading={paymentLoading} onClick={getPayment}>Rp{event.extras.price}</Button>
    {/* <Upoint token={token} clientKey={GAME_LAUNCHER_UPOINT_CLIENT_KEY} onSuccess={onSuccess} /> */}
    <Payment param={{
      game_name: event.game.name, publisher_username: event.game.publisher.username, event_name: event.name
    }} open={modalPaymentOpen} onCloseButton={() => setModalPaymentOpen(false)} />
  </>
}

