import React, { FC } from 'react'
import { Button, Card, Col, Image, Row, Typography } from 'antd'
import { useGame } from 'services/game'
import { Event } from 'services/event'
import { useTranslation } from 'react-i18next'
import './Card.scss'
import moment from 'moment'
import { Link } from 'react-router-dom'
export interface TournamentCardProps {
  event: Event
}
const TournamentCard: FC<TournamentCardProps> = ({
  event
}) => {
  const gameService = useGame()
  const { t } = useTranslation()
  return (<Card className='event-card'>
    <Row>
      <Col xs={24} xl={4}>
      <Link to={`/publishers/${event.game.publisher.username}/games/${event.game.name}/events/${event.name}`}>

        <Image
          src={event.poster}
        />
</Link>
      </Col>
      <Col xs={24} xl={16} style={{
        padding: 20
      }}>
        <Typography.Title level={5}>
          {event.display_name} -  {event.game.display_name}
        </Typography.Title>
        <Row>
          <Col xs={4} xl={2}>
          <Link to={`/publishers/${event.game.publisher.username}/games/${event.game.name}/events/${event.name}`}>
            <Image
              width={`100%`}
              src={gameService.getIcon(event.game)}
            />
            </Link>
          </Col>
          <Col xs={20} xl={10}>
            <table style={{textAlign:'left', width:'100%'}}>
              <tr>
                <td>
                  <Typography.Text  >
                    Start Time
                  </Typography.Text>
                </td>
                <td>
                
                  <Typography.Text strong>
                    {
                      moment(event.started_at).format(`hh:mm dddd`)
                    }
                    <br/>
                    {
                      moment(event.started_at).format(`DD MMMM YYYY`)
                    }
                  </Typography.Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography.Text>
                    End Time
                  </Typography.Text>
                </td>
                <td>
                  <Typography.Text strong>
                    {
                      moment(event.ended_at).format(`hh:mm dddd`)
                    }
                    <br/>
                    {
                      moment(event.ended_at).format(`DD MMMM YYYY`)
                    }
                  </Typography.Text>
                </td>
              </tr>
            </table>
          </Col>
          <Col className={'center-content'} xs={8} xl={4}>
            <Typography.Text>{t(`Prize`)}</Typography.Text>
            <Typography.Title level={5}>{event.extras.prize} Poin</Typography.Title>
          </Col>
          <Col className={'center-content'} xs={8} xl={4}>
            <Typography.Text>{t(`Entry fee`)}</Typography.Text>
            <Typography.Title level={5}>IDR {event.extras.price || 0}</Typography.Title>
          </Col>
          <Col className={'center-content'} xs={8} xl={4}>
            <Typography.Text>{t(`Loby`)}</Typography.Text>
            <Typography.Title level={5}>{event.participants}/{event.max_participants} Participants</Typography.Title>

          </Col>
        </Row>
      </Col>
      <Col className={'center-content'} xs={24} xl={4}>
        <Link style={{margin: '0auto', verticalAlign: 'middle'}} to={`/publishers/${event.game.publisher.username}/games/${event.game.name}/events/${event.name}`}>
          <Button>
            {t(`Participate`)}
          </Button>
        </Link>

      </Col>
    </Row>
  </Card>)
}
export default TournamentCard