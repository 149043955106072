import { Card, Image, Typography,Button, Row, Col } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import useReward, { Reward } from 'services/rewards'
import moment from 'moment'
import { DollarOutlined, MoneyCollectOutlined } from '@ant-design/icons'
export const Timer = ({ endTime }) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const _isExpired = () => {
    return  new Date(endTime).getTime() < new Date().getTime()
  }
  const getDiff = () => {
    const now = new Date()
    const startDate = moment(now)
    const timeEnd = moment(new Date(endTime))
    const diff = timeEnd.diff(startDate)
    const diffDuration = moment.duration(diff)
    setTime({
      days: diffDuration.days(),
      hours: diffDuration.hours(),
      minutes: diffDuration.minutes(),
      seconds: diffDuration.seconds(),
    })
  }
  useEffect(() => {
    const interval = setInterval(() => getDiff(), 1000)
    return () => clearInterval(interval)
  }, [])
  return <>
   {_isExpired() && <><Typography.Text style={{fontWeight: 'bold'}}>Expired</Typography.Text><br/></>}
   {
    !_isExpired() &&  
    <Row style={{ textAlign: 'center', flex: 1 }}> 
      <Col style={{ flex: 1 }}></Col>
      { time.days > 0 && <Col><div style={{ background: '#909090', border: '1px solid #808080', color: '#fff', margin: 3, padding: 3}}>{time.days}</div></Col> }
      <Col><div style={{ background: '#909090', border: '1px solid #808080', color: '#fff', margin: 3, padding: 3}}>{time.hours}</div></Col>
      <Col><div style={{ background: '#909090', border: '1px solid #808080', color: '#fff', margin: 3, padding: 3}}>{time.minutes}</div></Col>
      <Col><div style={{ background: '#909090', border: '1px solid #808080', color: '#fff', margin: 3, padding: 3}}>{time.seconds}</div></Col>
      <Col style={{ flex: 1 }}></Col>
    </Row>
    }
  </>
}

export interface RewardCardProps {
  reward: Reward
}


const RewardCard: FC<RewardCardProps> = ({ reward }) => {
  const {redeem} = useReward()
  const _isDisabled = () => {
    return  new Date(reward.expire).getTime() < new Date().getTime()
  }
  const _redeem = () => {
    redeem(reward.id)
  }
  return <Card style={{
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center', margin: 10
  }}>
    <Timer endTime={reward.expire}/>
    <Image src={reward.image}/>
    <div>
      <Typography.Text style={{ fontWeight: 'bold' }}>{reward.name}</Typography.Text>
    </div>
    <Button disabled={_isDisabled()} onClick={_redeem} type="primary" ><DollarOutlined/>{reward.price}</Button>
  </Card>
} 

export default RewardCard