import { Button, Col, Image, Row, Typography } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { Event } from 'services/event'
import moment from 'moment'
export interface TournamentDetailBannerProps {
  event: Event
}
const TournamentDetailBanner: FC<TournamentDetailBannerProps> = ({ event }) => {
  const { t } = useTranslation()
  return (<>
    <Row>
      <Col xs={24} className={'center-content'}>
        {
          event.video && (
            <ReactPlayer
              style={{
                margin: '0 auto'
              }}
              width='75%'
              url={event.video}
            />
          )
        }
        {
          !event.video && (
            <Image
              style={{
                margin: '0 auto'
              }}
              width='75%'
              src={event.poster}
            />
          )
        }
      </Col>
    </Row>

  </>)
}

export default TournamentDetailBanner