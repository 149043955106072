/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'

import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import ModalLogin, { MODAL_LOGIN_VISIBLE_GS } from './ModalLogin'
import openid, { LOGGED_IN_ST, TOKEN, Token, TOKEN_ST, UNAUTHORIZED_CODE, UserProfile } from 'services/openid'
import { useGlobalState } from 'store'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useStorageWithGlobalState } from 'services/storage'
import Avatar from 'antd/lib/avatar/avatar'
import { useHistory } from 'react-router'
import { ISLOADING_AVATAR_CHANGED } from './Avatar'
export const USER_PROFILE_GS = 'user-profile'
export const PROFILE_INIT = {} as UserProfile

const Login: FC = () => {
  const { t } = useTranslation()
  // let tk = TOKEN
  const [loginVisible, setLoginVisible] = useGlobalState(MODAL_LOGIN_VISIBLE_GS, false)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingAvatar, setLoadingAvatar] = useGlobalState(ISLOADING_AVATAR_CHANGED, false)

  const [userProfile, setUserProfile] = useGlobalState(USER_PROFILE_GS, PROFILE_INIT)
  const [token, setToken] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const Openid = openid()
  const _done = (token?: Token) => {
    setLoginVisible(false)
    token && fetchProfile(token)
  }
  const fetchProfile = (token?: Token) => {
    setLoading(true)
    Openid.getProfile(token).then(profile => {
      setUserProfile(profile)
    }).catch(e => {
      if (e.code === UNAUTHORIZED_CODE) {
        setToken(undefined)
      }
    }).finally(() => {
      setLoading(false)
    })
  }
  const componentDidMount = () => {
    return fetchProfile()
  }
  const _logout = () => {
    setToken(undefined)
    if (history.location.pathname.indexOf('accounts') !== -1) window.location.assign('/')
    else window.location.reload()
  }
  const _goToAccount = () => {
    history.push(`/accounts`)
  }
  useEffect(componentDidMount, [])

  return (<>
    {
      loading && (
        <Spin indicator={<LoadingOutlined />} />
      )
    }
    {
      !loading && (token === TOKEN || token === null) &&
      // !loading && 
      (
        <>
          <Button onClick={() => setLoginVisible(true)}>
            {t('login')}
          </Button>
          <ModalLogin onDone={_done} />
        </>
      )
    }
    {
      !loading && (token !== TOKEN && token !== null) && (
        <Dropdown overlay={
          (
            <Menu>
              <Menu.Item onClick={_goToAccount}>Account</Menu.Item>
              <Menu.Item onClick={_logout}>Logout</Menu.Item>
            </Menu>
          )
        } trigger={['click']}>

          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {loadingAvatar && <Spin />}
            {!loadingAvatar && <Avatar src={userProfile.avatar as string} />}
          </a>
        </Dropdown>


      )
    }
  </>)
}

export default Login