import { AxiosRequestConfig } from "axios"
import { TOKEN, TOKEN_ST } from "./openid"
import Request, { UNAUTH_CODE } from "./request"
import { useStorageWithGlobalState } from "./storage"
import qs from 'query-string'
import { DataPaginated, Game } from "./game"
const GAME_LAUNCHER_GAME_BACKEND_URL = window._env_.GAME_LAUNCHER_GAME_BACKEND_URL
export const EVENT_LOADER_KEY = 'event-data'

export interface EventExtras {
  price?: number
  prize?: number
}

export interface Score {
  player_username?: string
  player_name?: string
  score: number
}

export interface Rank extends Score {
  rank: number
}

export interface Event {
  game: Game
  name: string
  display_name: string
  started_at: Date
  ended_at: Date
  max_participants: number
  description: string
  tags: string[]
  extras: EventExtras
  creator: string
  created_at: Date
  updated_at: Date
  poster: string
  participants: number
  participated?: boolean
  my_rank?: Rank
  my_scores?: DataPaginated<Score[]>
  video?: string
}
export interface MyEventSummary {
  count_participated: number
  count_wins: number
  count_now_playing: number
}
export const useEvent = () => {
  const [token, setToken] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const clientEvent = new Request({
    baseURL: GAME_LAUNCHER_GAME_BACKEND_URL,
    headers: {
      Authorization: token && `Bearer ${token.access_token}`
    },
    paramsSerializer: function(p) {
      console.log(p)
      return ''
    }
  })
  clientEvent.failure(UNAUTH_CODE, () => {
    !token && setToken(undefined)
  })
  return ({
    async getEvent(publisher: string, game: string, event: string) {
      const e = await clientEvent.get<DataPaginated<Event>>(`/publishers/${publisher}/games/${game}/events/${event}`)
      return e.data
    },
    getEvents(pages: number = 1, rows: number = 10) {
      const p = qs.stringify({
        pages,
        rows
      })
      return clientEvent.get<DataPaginated<Event[]>>(`/events?${p}`)
    },
    getMyEvents(pages: number = 1, rows: number = 10) {
      const p = qs.stringify({
        pages,
        rows
      })
      return clientEvent.get<DataPaginated<Event[]>>(`/events/me?${p}`)
    },
    async getMySummary(): Promise<MyEventSummary> {
      const res = await clientEvent.get(`/events/me`)
      return res.data
    },
    async getScores(publisher: string, game: string, event: string): Promise<Score[]> {
      const res = await clientEvent.get(`/publishers/${publisher}/games/${game}/events/${event}/scores`)
      return res.data
    },
    participate(publisher: string, game: string, event: string): Promise<void> {
      return clientEvent.post(`/publishers/${publisher}/games/${game}/events/${event}/participate`, {})
    },
    async generateTokenPayment(event: Event): Promise<string> {
      const r = await clientEvent.post(`/publishers/${event.game.publisher.username}/games/${event.game.name}/events/${event.name}/pay/browser`)
      return r.data.token
    }
  })
}