import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './header.scss'
import { DownOutlined, MenuOutlined } from '@ant-design/icons'
import {
  Layout, Menu, Input, Image, Dropdown, Anchor as Link, Row, Col, Button
} from 'antd'
import Categories from './Categories'
import { useDeepEffect, useGlobalState, useQuery } from 'store'
import { OVERLAY_GS } from '../../layout/Overlay'
import Account from 'components/Account'
import {
  Link as RouteLink, useHistory
} from 'react-router-dom'
import qs from 'query-string'

import Notification from './Notification'
import { useConfig } from 'services/config'
import { useStorageWithGlobalState } from 'services/storage'
import { TOKEN, TOKEN_ST } from 'services/openid'
const { Search } = Input
const {
  Header: PageHeader
} = Layout
export const GAME_SEARCH_GS = 'gameSearch'

const Header: FC = (props) => {
  const config = useConfig()
  const { t } = useTranslation()
  const [_, setOverlay] = useGlobalState(OVERLAY_GS, false)
  const [token] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const history = useHistory()
  const [gameSearch, setGameSearch] = useGlobalState(GAME_SEARCH_GS, '')
  const [search, setSearch] = useState('')
  const queries = useQuery()
  const [showMenu, setShowMenu] = useState(false)
  const onSearch = ({ target: { value } }) => {
    setSearch(value)
  }

  const onSearchBlur = () => {
    setGameSearch(search)
    if (history.location.pathname.indexOf('/search') !== -1) return
    let params = {
      modes: queries.get('modes'),
      categories: queries.get('categories'),
      search: search
    }
    history.push({
      pathname: `/search`,
      search: `?${qs.stringify(params)}`
    })
  }
  useEffect(() => {
    setSearch(queries.get('search') ? queries.get('search') as string : '')
    setGameSearch(queries.get('search') ? queries.get('search') as string : '')
  }, [])

  useDeepEffect(() => {
    if (!gameSearch ) return
    let params = {
      modes: queries.get('modes'),
      categories: queries.get('categories'),
      search: gameSearch
    }
    history.push({
      pathname: `/search`,
      search: `?${qs.stringify(params)}`
    })
  }, [gameSearch])
  return (
    <>
      <div style={{ height: '64px' }} />
      <PageHeader style={{
        position: 'fixed',
        zIndex: 10,
        width: '100%',
        background: '#fff',
        boxShadow: '0px 0px 9px 3px rgba(41,41,41,.25)'
      }}>
        <Row >
          <Col xl={24} xs={0}>
        <Menu mode='horizontal' style={{
          border: 0,
          padding: 0,
          height: '80%',
          
        }}
        >
          <Menu.Item onClick={() => window.location = '/' as any as Location}>
            <img src={config.LOGO_URL} width={100} />
          </Menu.Item>
          <Dropdown overlay={Categories} trigger={['click']} onVisibleChange={visible => {
            setOverlay(visible)
          }}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {t('categories')} <DownOutlined />
            </a>
          </Dropdown>
          <Menu.Item>
            <RouteLink to={`/tournament`}>
              {t('tournament')}
            </RouteLink>
          </Menu.Item>
          
          <Menu.Item>
            <Search value={search} style={{ display: 'inline-block', verticalAlign: 'middle'}} placeholder={t(`Search game`)} onSearch={onSearchBlur} onChange={onSearch} onBlur={onSearchBlur} enterButton />

          </Menu.Item>
          <Menu.Item style={{
            float: 'right'
          }}>
            <Account />
          </Menu.Item>
          <Menu.Item style={{
            float: 'right'
          }}>
            { !(!token || token === null)  && (
              <Notification />
            )}
          </Menu.Item>
          <Menu.Item style={{
            float: 'right'
          }}>
            {/* <Search /> */}
          </Menu.Item>
        </Menu>
        </Col>

        <Col xl={0} xs={24}>
          <Row>
            <Col xs={12}>
            <a href="/">
            <img src={config.LOGO_URL} height="25px"/>
            </a>
            </Col>
            <Col xs={12} style={{textAlign: 'right'}}>
            { !(!token || token === null)  && (
              <Notification />
            )}
            <Button onClick={() => setShowMenu(!showMenu)} icon={<MenuOutlined/>} />
            </Col>
          </Row>
            {showMenu &&
            <Menu style={{
          border: 0,
          padding: 0,
          width: '100%'       
        }}
        >
        <Menu.Item>
          <Dropdown overlay={Categories} trigger={['click']} onVisibleChange={visible => {
            setOverlay(visible)
          }}>
            <a className="ant-dropdown-link menu-sm-categories" onClick={e => e.preventDefault()}>
              {t('categories')} <DownOutlined />
            </a>
          </Dropdown>
          </Menu.Item>
          <Menu.Item>
            <RouteLink to={`/tournament`}>
              {t('tournament')}
            </RouteLink>
          </Menu.Item>
          
          <Menu.Item>
            <Search value={search} style={{ display: 'inline-block', verticalAlign: 'middle'}} placeholder={t(`Search game`)} onSearch={onSearchBlur} onChange={onSearch} onBlur={onSearchBlur} enterButton />

          </Menu.Item>
          <Menu.Item>
            <Account />
          </Menu.Item>
        </Menu>
}
        </Col>
        </Row>
      </PageHeader>
    </>
  )
}

export default Header