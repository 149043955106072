import React, { FC } from 'react'
import { Event } from 'services/event'
import TournamentCard from '../Card'

export interface TournamentSetProps {
  events: Event[]
}

export const TournamentSet: FC<TournamentSetProps> = ({
  events
}) => {
  return <>
    {
      events.map((e, i) => (
        <TournamentCard key={`event-${i}`} event={e} />
      ))
    }
  </>
}

export default TournamentSet