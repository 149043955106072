import { Col, Divider, Row } from 'antd'
import React, { FC } from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { StarFilled } from '@ant-design/icons'
import './summary.scss'
const {
  Text,
  Title
} = Typography

export interface SummaryProps {
  stars: number,
  minimum_age: number,
  rank: string,
  reviews: number
}
const Summary: FC<SummaryProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Row className="game-detail-summary">
      <Col span={1} />
      <Col span={6}>
        <Text strong>{props.stars} <StarFilled type='primary' /></Text><br />
        <Text style={{
          fontSize: 10
        }}>{props.reviews} {t(`reviews`)}</Text>
      </Col>
      <Col span={1}><div></div></Col>
      <Col span={6}>
        <Text strong>{props.rank}</Text>
      </Col>
      <Col span={1}><div></div></Col>
      <Col span={6}>
        <Text strong>{props.minimum_age === 0 ? t(`All ages`) : `${props.minimum_age}+`}</Text>
      </Col>
    </Row>
  )
}

export default Summary