import { Card, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import usePlayer from 'services/player'

export interface PublisherProps {
  username: string
}
const Publisher: FC<PublisherProps> = ({
  username
}) => {
  const { t } = useTranslation()
  const {player} = usePlayer(username)
  return (
    <Card>
      <Title level={5}>{t(`Publisher`)}</Title>
      {player ? player.fullname : <Spin/>}
    </Card>
  )
}

export default Publisher