import { USER_PROFILE_GS } from 'components/Account/Account'
import { GameInfo } from 'components/Games/Detail'
import GameAssets from 'components/Games/Detail/GameAssets'
import GameRating from 'components/Games/Detail/GameRating'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Game, useGame } from 'services/game'
import { useGlobalState, useLoader } from 'store'

export const GAME_LOADER_KEY = 'game'

export const params = {
  name: `name`,
  publisher: `publisher`
}
const GameDetail = () => {
  const [game, setGame] = useState({} as Game)
  const param = useParams()
  const gameService = useGame()
  const { t } = useTranslation()
  const [userProfile] = useGlobalState(USER_PROFILE_GS)

  const [startGameLoader, stopGameLoader] = useLoader(GAME_LOADER_KEY)
  const componentDidMount = () => {
    fetchGame()
  }
  const fetchGame = () => {
    startGameLoader(t(`Fetching game`))
    gameService
      .detail(param[params.publisher], param[params.name])
      .then((game) => {
        setGame(game)
      }).catch(e => {

      }).finally(() => {
        stopGameLoader()
      })
  }
  
  useEffect(componentDidMount, [])
  useEffect(componentDidMount, [userProfile])
  return (
    <>
      <GameInfo game={game} />
      <GameAssets game={game} />
      <GameRating game={game} onRateRefresh={fetchGame} />
    </>
  )
}

export default GameDetail