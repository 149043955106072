import { Card, Col, Row, Typography } from 'antd'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useReward from '../../services/rewards'
import RewardCard from './RewardCard'
const Reward: FC = () => {
  const { 
    listReward,
    fetch,
    page
  } = useReward()
  const {t} = useTranslation()
  useEffect(() => {
    fetch()
  }, [])
  return <>
    <Typography.Title level={4}>{t(`Reedem Coin`)}</Typography.Title>
    <Row>
      {
        listReward.map((r, i) => (<>
          <Col xs={24} xl={6} key={`item-reward-${i}`}>
            <RewardCard reward={r}/>
          </Col>
          </>
        ))
      }
    </Row>
  </>
} 

export default Reward