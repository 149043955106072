/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from 'antd'
import React, { FC, useEffect } from 'react'
import { MoneyCollectFilled } from '@ant-design/icons'
import useCoin from 'services/coin'

const Coin: FC = () => {
  const {coin, get} = useCoin()
  useEffect(() => {
    get()
  }, [])
  return <>
    <Row>
      <Col>
        <MoneyCollectFilled/>
      </Col>
      <Col style={{flex: 1}}>
        <Typography.Text>Poin: </Typography.Text>
        <Typography.Text>{coin}</Typography.Text>
      </Col>
    </Row>
  </>
}

export default Coin