import { CreditCardOutlined, LoadingOutlined, PicCenterOutlined, TrophyOutlined } from '@ant-design/icons'
import { Card, Col, Image, Row, Spin, Typography, Button, Modal } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Event, Score, useEvent } from 'services/event'
import { getAvatar, Token, TOKEN, TOKEN_ST } from 'services/openid'
import TournamentDetailBanner from './Banner'
import { Tabs } from 'antd';
import moment from 'moment'
import './Detail.scss'
import TournamentLeaderboard from './Leaderboard'
import { Loader, useLoader } from 'store'
import { GamePlay } from 'components/Games/Detail/GameInfo/GameInfo'
import { useGame } from 'services/game'
import { useStorageWithGlobalState } from 'services/storage'
import { TournamentPayment } from './TournamentPayment'
import usePlayer from 'services/player'

const { TabPane } = Tabs;
const SCORES: Score[] = []
const EVENT_REGISTER_LOADER_KEY = `event:register`
export interface OnTournamentDetailChanged {
  (event: Event)
}
export interface TournamentDetailProps {
  event: Event
  changed?: OnTournamentDetailChanged
}
const TournamentDetail: FC<TournamentDetailProps> = ({ event, changed }) => {
  const { t } = useTranslation()
  const [token] = useStorageWithGlobalState<Token>(TOKEN_ST, TOKEN)
  const { player } = usePlayer(event.creator)

  const [scoreLoader, setScoreLoader] = useState(false)
  const [playerVisible, setPlayerVisible] = useState(false)
  const [scores, setScores] = useState(SCORES)
  const [playUrl, setPlayUrl] = useState('')
  const gameService = useGame()
  const [startRegisterLoader, stopRegisterLoader, registerLoader] = useLoader(EVENT_REGISTER_LOADER_KEY)
  const eventService = useEvent()
  const register = () => {
    if (!token) return alert(t(`You are not logged in`))
    if (!token.id_token) return alert(t(`You are not logged in`))
    if (event.extras.price) {
      return alert(t(`paid event not implemented`))
    }
    if (ended.toDate().getTime() <= (new Date()).getTime()) {
      componentDidMount()
      return Modal.error({ title: t(`Event finished`) })
    }
    if (registerLoader) return
    startRegisterLoader(t(`Registering`))
    eventService.participate(
      event.game.publisher.username,
      event.game.name,
      event.name)
      .then(() => {
        changed && changed({ ...event, participated: true })
      })
      .catch((err) => {
        err && err.code === 412 && Modal.error({ title: t(`This event reached max participant quota`) })
      }).finally(() => stopRegisterLoader())
  }
  const started = moment(event.started_at)
  const ended = moment(event.ended_at)
  const now = () => {
    return new Date().getTime()
  }
  const fetchScores = () => {
    setScoreLoader(true)
    eventService.getScores(
      event.game.publisher.username,
      event.game.name,
      event.name
    ).then(s => {
      setScores(s)
    }).catch(() => {

    }).finally(() => setTimeout(() => setScoreLoader(false), 1500))
  }
  const componentDidMount = () => {
    fetchScores()
    gameService.play_url(
      event.game.publisher.username,
      event.game.name,
      event.name
    ).then(pUrl => {
      setPlayUrl(pUrl)
    })
  }
  const eventPlayDone = () => {
    setPlayerVisible(false)
  }
  useEffect(componentDidMount, [])
  useEffect(componentDidMount, [playerVisible])
  return (<>
    <TournamentDetailBanner event={event} />

    <Row>
      <Col xs={24} className={'center-content'}>
        <Typography.Title>
          {event.display_name}
        </Typography.Title>
        {
          event.participated && playUrl !== '' && playUrl !== '' && now() >= started.toDate().getTime() && now() < ended.toDate().getTime() && (
            <Button onClick={() => setPlayerVisible(true)}>
              {t(`Play`)}
            </Button>
          )
        }
        {
          now() < ended.toDate().getTime() && <>
            {
              !event.participated ? event.extras.price ? <TournamentPayment onSuccess={() =>
                window.location.reload()
              } event={event} /> : (
                <Button onClick={register}>
                  {t(`Register`)}
                </Button>
              ) : (
                <></>
              )
            }
            &nbsp;
          </>
        }
        {
          now() < started.toDate().getTime() && (
            <>
              <Button >
                {t(`Event not started`)}
              </Button>&nbsp;
            </>
          )
        }
        {
          now() > ended.toDate().getTime() && (
            <>
              <Button>
                {t(`Event finished`)}
              </Button>&nbsp;
            </>
          )
        }
      </Col>
    </Row>
    <Row>
      <Col xs={24} className={'center-content'}>
        <br />
        <Typography.Text>
          {t(`Event time`)}:
        </Typography.Text>
        <br />
        <Typography.Text strong style={{
          padding: 5,
          borderRadius: 5,
          background: `#ddd`,
          margin: 10
        }}>
          {
            moment(event.started_at).format(`HH:mm dddd, DD MMMM YYYY`)
          }
        </Typography.Text>
        <Typography.Text strong style={{
          padding: 5,
          borderRadius: 5,
          background: `#ddd`,
          margin: 10
        }}>
          {
            moment(event.ended_at).format(`HH:mm dddd, DD MMMM YYYY`)
          }
        </Typography.Text>
      </Col>
    </Row>
    <br />

    <Row>
      <Col xs={9}>
        <Row gutter={10}>
          <Col>
            <Card className="profile-card">
              <Image
                style={{
                  borderRadius: 50
                }}
                width={100}
                height={100}
                src={getAvatar(event.creator)}
              />
            </Card>
          </Col>
          <Col>

            <br />
            <br />
            <Typography.Text strong>
              {
                player ? player.fullname : <Spin />
              }
            </Typography.Text>
          </Col>
        </Row>

      </Col>
      <Col className={'center-content'} xs={5}>
        <Typography.Text>{t(`Prize`)}</Typography.Text><br />
        <TrophyOutlined style={{
          fontSize: 50
        }} />
        <Typography.Title level={4}>{event.extras.prize} Poin</Typography.Title>
      </Col>
      <Col className={'center-content'} xs={5}>
        <Typography.Text>{t(`Entry fee`)}</Typography.Text><br />
        <CreditCardOutlined style={{
          fontSize: 50
        }} />
        <Typography.Title level={4}>{event.extras.price ? `IDR ${event.extras.price}` : 'free'}</Typography.Title>
      </Col>
      <Col className={'center-content'} xs={5}>
        <Typography.Text>{t(`Loby`)}</Typography.Text><br />
        <PicCenterOutlined style={{
          fontSize: 50
        }} />
        <Typography.Title level={4}>{event.participants}/{event.max_participants} Participants</Typography.Title>
      </Col>
    </Row>
    <br />
    <Row >

    <Col xs={24} xl={0} className="center-content">
        {
          event.my_rank && (
            <>
              <Typography.Title>
                #{event.my_rank.rank}
              </Typography.Title>
              <Typography.Text strong>
                {t('Score')}: {event.my_rank.score}
              </Typography.Text>
            </>
          )
        }
      </Col>
      <Col xs={24} xl={14}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t(`Leaderboard`)} key="1">
            {scoreLoader && <Spin indicator={<LoadingOutlined />} />}
            {!scoreLoader && <TournamentLeaderboard scores={scores} />}
          </TabPane>
          <TabPane tab={t(`Game Info`)} key="2">
            <Typography.Text>
              {event.game.description}
            </Typography.Text>
          </TabPane>
          <TabPane tab={t(`Event Info`)} key="3">
            <Typography.Text>
              {event.description}
            </Typography.Text>
          </TabPane>
        </Tabs>
      </Col>

      <Col xs={0} xl={10} className="center-content">
        {
          event.my_rank && (
            <>
              <Typography.Title>
                #{event.my_rank.rank}
              </Typography.Title>
              <Typography.Text strong>
                {t('Score')}: {event.my_rank.score}
              </Typography.Text>
            </>
          )
        }
      </Col>
    </Row>
    {playUrl !== '' && <GamePlay game={event.game} gameUri={playUrl} onDone={eventPlayDone} visible={playerVisible} />}

    <br />
  </>)
}

export default TournamentDetail