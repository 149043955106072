import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { Game, RateAggs } from 'services/game'
import Summary, { GameRate, GameRateItem } from './Summary'
import './rating.scss'
import Form from './Form'
import List from './List'
import { useGlobalState } from 'store'
import { RATE_REFRESH_GS } from './List/List'
import { Token, TOKEN, TOKEN_ST } from 'services/openid'
import { useStorageWithGlobalState } from 'services/storage'
import { delay } from 'services/utils'

export interface GameRatingProps {
  game: Game
  onRateRefresh?: Function
}

export const GameRatingOverlay: FC = () => {
  return (<></>)
}
const GameRating: FC<GameRatingProps> = ({
  game, onRateRefresh
}) => {
  const [rateRefresh, setRateRefresh] = useGlobalState(RATE_REFRESH_GS, false)
  const [token] = useStorageWithGlobalState<Token>(TOKEN_ST, {} as Token)
  if (!game.rate_agg) return <GameRatingOverlay />

  const countTotalRate = (): GameRate => {
    let total = 0
    let rates: GameRateItem[] = []

    for (let i = 0; i < game.rate_agg.length; i++) {
      const element = game.rate_agg[i];
      total += element.count
    }
    for (let i = 0; i < game.rate_agg.length; i++) {
      const element = game.rate_agg[i];
      rates.push({
        ...element,
        percent: element.count / total * 100
      })
    }
    return {
      total,
      rates
    }
  }
  const rates = countTotalRate()
  const onRate = async () => {
    await delay(1500)
    onRateRefresh && onRateRefresh()
    setRateRefresh(true)
  }
  return (
    <Row>
      <Col xs={24} xl={6}>
        <Summary rateAvg={game.rate_avg} rates={rates} />
        {token && (
          <Form game={game} onRate={onRate} />
        )}
      </Col>
      <Col xs={24} xl={18}>
        <List game={game} />
      </Col>
    </Row>
  )
}

export default GameRating