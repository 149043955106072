import { Typography, Button, Input, DatePicker, Select } from 'antd'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalState } from 'store'
import { PROFILE_INIT, USER_PROFILE_GS } from '../Account'
import moment from 'moment'
import OpenID from '../../../services/openid'
const { Option } = Select
export const Profile: FC = () => {
  const [userProfile, setUserProfile] = useGlobalState(USER_PROFILE_GS, PROFILE_INIT)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const openid = OpenID()
  const { t } = useTranslation()
  const save = () => {
    if (loading) return
    setLoading(true)
    openid
      .editProfile(userProfile)
      .then(() => {
        // donothing
      })
      .catch(e => {
        // also donothing
      })
      .finally(() => {
        setLoading(false)
        setIsEdit(false)
      })
  }
  return <>
    <Typography.Title>{t(`Profile Data`)} <Button onClick={() => setIsEdit(!isEdit)}>{t(`Edit`)}</Button> </Typography.Title>
    <Typography.Text>{t(`Fullname`)}</Typography.Text><br />
    {!isEdit ?
      <Typography.Text>{userProfile.fullname}</Typography.Text> :
      <Input value={userProfile.fullname} onChange={({ target: { value: fullname } }) => setUserProfile({ ...userProfile, fullname })} />}
    <br /><br />
    <Typography.Text>{t(`Birth date`)}</Typography.Text><br />
    {!isEdit ?
      <Typography.Text>{moment(userProfile.birthdate || '2000-01-01').format(`D MMMM Y`)}</Typography.Text> :
      <DatePicker style={{ width: '100%' }} format={`D MMMM Y`} value={moment(userProfile.birthdate || '2000-01-01')} onChange={(date) => setUserProfile({
        ...userProfile, birthdate: date?.format(`Y-M-D`)!
      })} />
    }
    <br /><br />
    <Typography.Text>{t(`Gender`)}</Typography.Text><br />
    {!isEdit ?
      <Typography.Text>{userProfile.gender || t(`Not specified`)}</Typography.Text> :
      <Select defaultValue="Gender" style={{ width: '100%' }} value={userProfile.gender} onChange={(gender) => setUserProfile({ ...userProfile, gender })}>
        <Option value="male">Male</Option>
        <Option value="female">Female</Option>
      </Select>
    }

    <br />
    <br />
    {isEdit &&
      <Button type={'primary'} onClick={() => save()} loading={loading}>{t(`Save`)}</Button>
    }
  </>
}

export default Profile