import { Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Game } from 'services/game'
import GameImageAsset from './Images'
import GameVideoAsset from './Videos'

export interface GameAssetsProps {
  game: Game
}

const GameAssets: FC<GameAssetsProps> = ({
  game
}) => {
  const {t} = useTranslation()
  return (
    <Row>
      <Col xs={24} xl={14} >
        <Title level={5}>{t(`Images`)}</Title>
        <GameImageAsset highlights={game.highlight_images}/>
      </Col>
      <Col xs={24} xl={10}>
        <Title level={5}>{t(`Videos`)}</Title>
        <GameVideoAsset videos={game.videos}/>
      </Col>
    </Row>
  )
}

export default GameAssets