import React, { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { LayoutProps } from '../layout/Main';

export interface RouteWithLayoutProps {
  component: FC<RouteComponentProps>
  layout: FC<LayoutProps>
  path: string | string[]
}
const RouteWithLayout: FC<RouteWithLayoutProps> = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
