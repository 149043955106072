import React from 'react';
import 'antd/dist/antd.css';
import './App.less';
import { Router } from 'react-router-dom';
import Routes from './router/Routes';
import { createBrowserHistory, History } from 'history';
import { InitTranslation } from './lang/i18n';
import GlobalLoader from 'components/GlobalLoader';
InitTranslation()
const browserHistory = createBrowserHistory();

function App() {
  return (
    <>
    <Router history={browserHistory as History}>
      <Routes/>
    </Router>

    <GlobalLoader/>
    </>
  );
}

export default App;
