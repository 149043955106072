import React, { FC } from 'react'
import { Game, RateAggs } from 'services/game'
import Rating from 'react-rating-stars-component'
import { Col, Progress, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'


export interface GameRateItem extends RateAggs {
  percent: number
}
export interface GameRate {
  total: number
  rates: GameRateItem[]
}
export interface GameRatingProps {
  game: Game
}

export interface RatingSummaryProps {
  rates: GameRate,
  rateAvg: number
}
const Summary: FC<RatingSummaryProps> = ({
  rates,
  rateAvg
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography.Title level={4}>{t(`Rating and Reviews`)}</Typography.Title>
      <Row className={'summary'}>
        <Col span={10}>
          <Typography.Title style={{
            margin: 0,
            textAlign: 'center'
          }}>{rateAvg}</Typography.Title>
          <Rating
            style={{
              margin: '0 auto'
            }}
            count={5}
            size={18}
            activeColor="#00875F"
            value={rateAvg}
            edit={false}
          />
        </Col>
        <Col span={12}>
          {
            rates.rates.map((v, i) => (
              <Row>
                <Col span={3}>
                  {v.star}
                </Col>
                <Col span={14}>
                  <Progress key={`rate-progres-${i}`} percent={v.percent as number} showInfo={false} />
                </Col>
                <Col span={7}>
                  &nbsp;
                  &nbsp;
                  {v.count}
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row>
    </>
  )
}

export default Summary