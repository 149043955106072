import React, { CSSProperties, FC, useEffect } from "react"
import { Pagination as AntdPagination } from 'antd'
export interface OnPageClickFunc {
  (page: number)
}
export interface PaginationProps {
  page: number
  totalPage: number
  sizePerPage: number
  totalRows: number,
  style?: CSSProperties
  onPageClick?: OnPageClickFunc
}
const Pagination: FC<PaginationProps> = ({
  page, totalRows, onPageClick, sizePerPage, style
}) => {
  return (
    <div>
      <AntdPagination
        style={style}
        defaultCurrent={1}
        current={page as number}
        total={totalRows as number}
        pageSize={sizePerPage as number}
        onChange={(page) => {
          onPageClick && onPageClick(page)
        }}
      />
    </div>
  )
}

export default Pagination