import React, { LegacyRef, useRef, useState } from 'react'
import { Avatar as AntdAvatar, Spin } from 'antd'
import { PROFILE_INIT, USER_PROFILE_GS } from './Account'
import { useGlobalState } from 'store'
import { AntDesignOutlined } from '@ant-design/icons'
import './avatar.scss'
import OpenID from '../../services/openid'
export const ISLOADING_AVATAR_CHANGED = 'isLoadingAvatarChanged'
const Avatar: React.FC = () => {
  const [userProfile] = useGlobalState(USER_PROFILE_GS, PROFILE_INIT)
  const [loading, setLoading] = useGlobalState(ISLOADING_AVATAR_CHANGED, false)
  const fileRef = useRef<HTMLInputElement>(null)
  const openid = OpenID()
  const changeAvatar = () => {
    if(loading) return
    fileRef.current?.click()
  }
  const fileChanged = () => {
    if(!fileRef.current?.files?.item(0)) return
    setLoading(true)
    openid
    .changeAvatar(fileRef.current?.files?.item(0)!)
    .then(() => {
      // donothing
    }).catch(() => {
      //donothing
    }).finally(() => setLoading(false))
    
  }
  return <div className="btn-change-avatar" onClick={changeAvatar}>
    {loading && <Spin/>}
    {!loading && <AntdAvatar
      src={userProfile.avatar}
      size={100}
      icon={<AntDesignOutlined />}
    />}
    <input style={{display: 'none'}}   accept="image/png, image/gif, image/jpeg" type="file" ref = {fileRef} onChange={fileChanged}/>
  </div> 
}

export default Avatar