import { APP_NAME, NOTIFICATION_URL } from "./config"
import { TOKEN, TOKEN_ST } from "./openid"
import Request, { UNAUTH_CODE } from "./request"
import { useStorageWithGlobalState } from "./storage"
import jwt from 'jwt-decode'
const req = new Request({
  baseURL: `${NOTIFICATION_URL}/applications/${APP_NAME}`
})
export interface NotificationItem {
  id: string
  title: string
  action: string
  subtitle: string
  message: string
  param: string
  readed: boolean
  created: Date
}
export const useNotification = () => {
  const [token] = useStorageWithGlobalState(TOKEN_ST, TOKEN)
  const userData: any = !token ? {} : token.id_token ? jwt(token.id_token) : {}
  return {
    async getNotification(): Promise<NotificationItem[]> {
      const notifications = await req.get(`/users/${userData.sub}/notifications`)
      return notifications
    },
    async getNextNotification(id): Promise<NotificationItem[]> {
      const notifications = await req.get(`/users/${userData.sub}/notifications/next/${id}`)
      return notifications
  
    },
    async getNewestNotification(id): Promise<NotificationItem[]> {
      const notifications = await req.get(`/users/${userData.sub}/notifications/newest/${id}`)
      return notifications
  
    },
    async getUnreadNotification(): Promise<number> {
      const notifications = await req.get(`/users/${userData.sub}/notifications/unread`)
      return notifications.count
    },
    async readNotification(id): Promise<void> {
      await req.put(`/users/${userData.sub}/notifications/${id}/read`, {})
    }
  }
}