import { Game, HomeData } from "services/game";

export const GameSample: Game = {
  display_name: "Loading",
  tagline: "Yeah",
  icon_image: "/placeholder-image.png",
  publisher: {
    username: 'atepdiaz'
  },
  name: 'bridge-pass'
} as any as Game
export const HomeSamples: HomeData[] = [{
  name: "Top rated games",
  games: [
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
  ],
  more: "/top-rated"
}, {
  name: "Most Playing",
  games: [
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
    GameSample,
  ],
  more: "/top-rated"
}]

export const GameSetSample = [
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
  GameSample,
]