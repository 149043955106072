import { useGlobalState } from "store"
export interface StorageDispatch<T> {
  (value?: T)
}
export const useStorage = function useStorage<T>(key: string, data: T): [T, StorageDispatch<T>] {
  let r = localStorage.getItem(key)
  return [
    r ? JSON.parse(r as string) : data,
    (value?: T) => {
      localStorage.setItem(key, JSON.stringify(value || null))
    }
  ]
}

export const getItem = (key: string) => JSON.parse(localStorage.getItem(key as string) || '')

export const useStorageWithGlobalState = function useStorageWithGlobalState<T>(key: string, data: T): [T, StorageDispatch<T>] {
  const [st, setST] = useStorage(key, data)
  const [gt, setGT] = useGlobalState(key, data)
  let r = JSON.stringify(gt) !== '{}'
  return [
    r ? gt || st : st,
    (v?: T) => {
      setGT(v as T)
      setST(v as T)
    }
  ]
}