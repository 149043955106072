import { Layout } from 'antd'
import TournamentDetail from 'components/Tournament/Detail'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Event, EVENT_LOADER_KEY, useEvent } from 'services/event'
import { Loader, useLoader } from 'store'
import { params as gameDetailParam } from './GameDetail'
export const params = {
  event_name: 'event_name',
  ...gameDetailParam
}
const EVENT: Event = null as any as Event
const TournamentDetailPage: FC<{}> = () => {
  const [event, setEvent] = useState(EVENT)
  const { t } = useTranslation()
  const [startEventLoader, stopeventLoader] = useLoader(EVENT_LOADER_KEY)
  const eventService = useEvent()
  const p = useParams()
  const getEvent = () => {
    startEventLoader(t(`Getting event`))
    eventService.getEvent(
      p[params.publisher],
      p[params.name],
      p[params.event_name],
    ).then(e => setEvent(e))
      .catch(() => {

      })
      .finally(() => stopeventLoader())
  }
  useEffect(getEvent, [p[params.event_name], p[params.publisher], p[params.name]])
  useEffect(getEvent, [])
  return (
    <Layout.Content>
      <>
        {event && <TournamentDetail event={event} changed={setEvent} />}
      </>
    </Layout.Content>
  )
}

export default TournamentDetailPage