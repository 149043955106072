import { useEffect, useState } from "react"
import { useConfig } from "./config"
import { UserProfile } from "./openid"
import Request from "./request"

const usePlayer = (username) => {
  const [player, setPlayer] = useState<UserProfile>({} as UserProfile)
  const [loading, setLoading] = useState(false)
  const config = useConfig()
  const clientGame = new Request({
    baseURL: config.BACKEND_HOST,
  })
  const get = () => {
    setLoading(true)
    clientGame.get(`/players/${username}`)
    .then(u => {
      setPlayer(u.data.info)
    })
    .catch(() => {

    })
    .finally(() => {
      setTimeout(() => setLoading(false), 1000) 
    })
  }
  useEffect(get, [])
  useEffect(get, [username])

  return {
    player,
    loading,
    get
  }
}

export default usePlayer