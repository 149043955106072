import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'antd'
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login'
import { FacebookProvider, LoginButton } from 'react-facebook'
import './login.scss'

import { FAPPSID, GAPPSID } from 'services/config'
import openid, { Token } from 'services/openid'
import { useGlobalState } from 'store'
export const MODAL_LOGIN_VISIBLE_GS = 'modal-login'

export interface LoginDone {
  (token?: Token): void
}
export interface LoginProps {
  onDone?: LoginDone
}
const ModalLogin: FC<LoginProps> = ({onDone}) => {
  const Openid = openid()
  const [isVisible, setIsVisible] = useGlobalState(MODAL_LOGIN_VISIBLE_GS, false)

  const _done = (token?: Token) => {
    setIsVisible(false)
    onDone && onDone(token)
  }
  const googleLoginSuccess = (res: GoogleLoginResponse ) => {
    Openid
    .google(res.tokenObj.id_token)
    .then(token => {
      _done(token)
    })
  }
  const facebookLoginSuccess = (token) => {
    Openid
    .facebook(token.tokenDetail.accessToken)
    .then(token => {
      _done(token)
    })
  }
  const onSocialError = (sType) => (error) => {
    console.log(sType, error)
  }
  return (
    <Modal 
      className={'login-modal'}
      title={null} 
      footer={null}
      visible={isVisible}
      destroyOnClose={false}
      closable={true}
      onCancel={() => _done()}
      >
        <Image
          src="/default-login.png"
        />
        <div 
          className={'login-body'}
        >
          <Row>
            <Col span={6}></Col>
            <Col span={12}>
              <GoogleLogin className='google-button' clientId={GAPPSID} onSuccess = {(res) => googleLoginSuccess(res as GoogleLoginResponse)} onFailure={onSocialError('google')}/>

              <FacebookProvider appId={FAPPSID}>
                <LoginButton
                  className='ant-btn facebook-button'
                  scope="email"
                  onCompleted={facebookLoginSuccess}
                  onError={onSocialError('google')}
                >
                  Facebook
                </LoginButton>
              </FacebookProvider>

            </Col>
            <Col span={6}></Col>
          </Row>
        </div>
    </Modal>
  )
}
export default ModalLogin