import Modal from 'antd/lib/modal/Modal'
import React, { FC } from 'react'
import OpenApp from "react-open-app"
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS } from 'react-device-detect'
import { useConfig } from 'services/config'
import qs from 'query-string'
import { Button } from 'antd'
import MobileStoreButton from 'react-mobile-store-button';
export interface PaymentProps {
  param: {
    game_name: string
    publisher_username: string
    event_name?: string
  }
  open: boolean
  onCloseButton?: Function
}

export const Payment: FC<PaymentProps> = ({ param, open, onCloseButton }) => {
  const {t} = useTranslation()
  const { 
    GAME_LAUNCHER_ANDROID_DEEPLINK, GAME_LAUNCHER_ANDROID_STORE_URL, GAME_LAUNCHER_IOS_DEEPLINK, GAME_LAUNCHER_IOS_STORE_URL
  } = useConfig()
  const getURL = () => {
    return isAndroid ? `${GAME_LAUNCHER_ANDROID_DEEPLINK}/mobile?${qs.stringify(param)}` : `${GAME_LAUNCHER_IOS_DEEPLINK}/mobile?${qs.stringify(param)}`
  }
  return (
    <Modal visible={open} footer={false} onCancel={() => onCloseButton && onCloseButton()}> 
      <p>{t(`Payment enabled only on mobile version, open link below`)}</p>
      { (isAndroid || isIOS) && 
        <OpenApp href={getURL()}>{t(`Open mobile launcher`)}</OpenApp>
        ||
        <>
        { GAME_LAUNCHER_ANDROID_STORE_URL &&  <MobileStoreButton store='android' url={GAME_LAUNCHER_ANDROID_STORE_URL}/> }
        { GAME_LAUNCHER_IOS_STORE_URL &&  <MobileStoreButton store='ios' url={GAME_LAUNCHER_IOS_STORE_URL}/> }
        </>
      }
    </Modal>
    )
}

export default Payment