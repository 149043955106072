export default {
  categories: 'Categories',
  tournament: 'Tournament',
  login: 'Login',
  playerNumber: 'Player number',
  all: 'All',
  genres: 'Genres',
  allGenres: 'All Genres',
  mode: 'Mode',
  multiPlayer: 'Multi-Player',
  singlePlayer: 'Single-Player'
}