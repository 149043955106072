declare global {
  interface Window {
    _env_?: any;
  }
}

export const GAPPSID = process.env.GAME_LAUNCHER_GOOGLE_APP_ID || window._env_.GAME_LAUNCHER_GOOGLE_APP_ID || ''
export const FAPPSID = process.env.GAME_LAUNCHER_FACEBOOK_CLIENT_ID || window._env_.GAME_LAUNCHER_FACEBOOK_CLIENT_ID || ''
export const CLIENT_ID = process.env.GAME_LAUNCHER_OPENID_CLIENT || (window._env_.GAME_LAUNCHER_OPENID_CLIENT && window._env_.GAME_LAUNCHER_OPENID_CLIENT.split(" ")[0])
export const CLIENT_SECRET = process.env.GAME_LAUNCHER_OPENID_CLIENT || (window._env_.GAME_LAUNCHER_OPENID_CLIENT && window._env_.GAME_LAUNCHER_OPENID_CLIENT.split(" ")[1])
export const OPENID_HOST = process.env.GAME_LAUNCHER_OPENID_URL || window._env_.GAME_LAUNCHER_OPENID_URL
export const BACKEND_HOST = process.env.GAME_LAUNCHER_GAME_BACKEND_URL || window._env_.GAME_LAUNCHER_GAME_BACKEND_URL
export const OPENID_SCOPE = process.env.GAME_LAUNCHER_OPENID_CONNECT_SCOPE || window._env_.GAME_LAUNCHER_OPENID_CONNECT_SCOPE
export const ABOUT_APP = process.env.GAME_LAUNCHER_ABOUT_APP || window._env_.GAME_LAUNCHER_ABOUT_APP || 'GameQoo adalah Cloud Gaming untuk streaming games PC di Indonesia. Rasakan cara main game PC original yang mudah dan murah sekarang!'
export const CONTACT_PHONE = process.env.GAME_LAUNCHER_CONTACT_PHONE || window._env_.GAME_LAUNCHER_CONTACT_PHONE || 'support@gameqoo.id'
export const CONTACT_EMAIL = process.env.GAME_LAUNCHER_CONTACT_EMAIL || window._env_.GAME_LAUNCHER_CONTACT_EMAIL || '081234567890'
export const NOTIFICATION_URL = process.env.GAME_LAUNCHER_NOTIFICATION_URL || window._env_.GAME_LAUNCHER_NOTIFICATION_URL || ''
export const BANNER_DATA = process.env.GAME_LAUNCHER_BANNER_DATA || window._env_.GAME_LAUNCHER_BANNER_DATA_IMAGES || `[{
  "action_link": "https://gameqoo.id",
  "source": {
    "type": "image",
    "uri": "https://s3-ap-southeast-1.amazonaws.com/emago-asset-dev/rfm-asset/Header-Gameqoo.jpg"
  },
  "description": "Rasakan cara baru main game PC original berkualitas melalui cloud dengan mudah dan murah sekarang!",
  "title": "Cloud Gaming for Everyone"
}]`

export const LOGO_URL = process.env.GAME_LAUNCHER_LOGO_URL || window._env_.GAME_LAUNCHER_LOGO_URL || '/logo.png'
export const FAVICON_URL = process.env.GAME_LAUNCHER_FAVICON_URL || window._env_.GAME_FAVICON_URL
export const APP_TITLE = process.env.GAME_LAUNCHER_APP_TITLE || window._env_.GAME_LAUNCHER_APP_TITLE || 'Game Launcher Platform'


export const GAME_LAUNCHER_UPOINT_CLIENT_KEY = process.env.GAME_LAUNCHER_UPOINT_CLIENT_KEY || window._env_.GAME_LAUNCHER_UPOINT_CLIENT_KEY || '5ffd09e61441fc65318e22a4'

export const GAME_LAUNCHER_ANDROID_DEEPLINK = process.env.GAME_LAUNCHER_ANDROID_DEEPLINK || window._env_.GAME_LAUNCHER_ANDROID_DEEPLINK || 'https://mobile.gameqoo.solusiteknologikreatif.id'

export const GAME_LAUNCHER_IOS_DEEPLINK = process.env.GAME_LAUNCHER_IOS_DEEPLINK || window._env_.GAME_LAUNCHER_IOS_DEEPLINK || 'https://mobile.gameqoo.solusiteknologikreatif.id'

export const GAME_LAUNCHER_ANDROID_STORE_URL = process.env.GAME_LAUNCHER_ANDROID_STORE_URL || window._env_.GAME_LAUNCHER_ANDROID_STORE_URL || 'https://play.google.com/gameqoo'

export const GAME_LAUNCHER_IOS_STORE_URL = process.env.GAME_LAUNCHER_IOS_STORE_URL || window._env_.GAME_LAUNCHER_IOS_STORE_URL || 'https://play.google.com/gameqoo'

export const APP_NAME = 'game-launcher'

export const useConfig = () => ({
  GAPPSID,
  FAPPSID,
  CLIENT_ID,
  CLIENT_SECRET,
  OPENID_HOST,
  BACKEND_HOST,
  OPENID_SCOPE,
  ABOUT_APP,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  NOTIFICATION_URL,
  BANNER_DATA: () => {
    try {
      const banner = JSON.parse(BANNER_DATA)
      if (!banner[0]) throw new Error("env not valid")
      return banner
    } catch (e) {
      console.error('BANNER_DATA env not array of BannerObject', e)
    }
  },
  LOGO_URL,
  FAVICON_URL,
  APP_TITLE,
  APP_NAME,
  GAME_LAUNCHER_ANDROID_DEEPLINK,
  GAME_LAUNCHER_ANDROID_STORE_URL,
  GAME_LAUNCHER_IOS_DEEPLINK,
  GAME_LAUNCHER_IOS_STORE_URL
})

