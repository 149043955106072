/* eslint-disable react-hooks/exhaustive-deps */
import { CloseSquareFilled, PlayCircleOutlined } from '@ant-design/icons'
import { Col, Row, Image, Typography, Button } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Game, useGame } from 'services/game'
import Publisher from './Publisher'
import Summary from './Summary'
import Iframe from 'react-iframe'
import Modal from 'antd/lib/modal/Modal'
import { useStorageWithGlobalState } from 'services/storage'
import { Token, TOKEN_ST } from 'services/openid'
import { useGlobalState } from 'store'
import { MODAL_LOGIN_VISIBLE_GS } from 'components/Account/ModalLogin'
import { GamePayment } from './GamePayment'
import { PROFILE_INIT, USER_PROFILE_GS } from 'components/Account/Account'
const {
  Paragraph,
  Title
} = Typography


export interface GamePlayProps {
  gameUri: string
  visible: boolean
  game: Game
  onDone: Function
}
export const GamePlay: FC<GamePlayProps> = ({
  gameUri, visible, game, onDone
}) => {
  const gameService = useGame()
  const [isVisible, setVisible] = useState(visible)
  const [uri, setUri] = useState('')
  const [playId, setPlayId] = useState(0)
  useEffect(() => {
    if (visible !== isVisible) {
      setVisible(visible)
      if (visible) {
        startPlay()
      }
    }
  }, [visible, isVisible])
  const startPlay = () => {
    setUri(gameUri as string)
    gameService.startPlay(game.publisher.username, game.name).then(playId => setPlayId(playId)).catch(e => {})
  }
  const finishPlay = () => {
    setUri('')
    gameService.endPlay(game.publisher.username, game.name, playId).then(playId => {}).catch(e => {})
  }
  const _done = () => {
    finishPlay()
    onDone && onDone()
  }
  const [
    height,
    width
  ] = [
      window.screen.availHeight,
      window.screen.availWidth
    ]
  return (
    <Modal
      className={'login-modal'}
      title={null}
      footer={null}
      visible={isVisible}
      destroyOnClose={false}
      closable={true}
      onCancel={_done}
      width={width}
      style={{ top: 10 }}
      bodyStyle={
        {
          height: height * 90 / 100
        }
      }
      closeIcon={
        <CloseSquareFilled style={{
          background: '#fff'
        }} />
      }
    >
      <Iframe
        width={'100%'}
        height={'100%'}
        url={uri} />
    </Modal>
  )
}

export interface GameInfoProps {
  game: Game
}
const GameInfo: FC<GameInfoProps> = ({ game }) => {
  const { t } = useTranslation()
  const gameService = useGame()
  const [playUrl, setPlayUrl] = useState('')
  const [playVisible, setPlayVisible] = useState(false)
  const [userProfile] = useGlobalState(USER_PROFILE_GS, PROFILE_INIT)
  const [gameRank, setGameRank] = useState('#')
  const [loginVisible, setLoginVisible] = useGlobalState(MODAL_LOGIN_VISIBLE_GS, false)
  const gameUpdate = () => {
    setPlayUrl('')
    if (!game.publisher) return
    gameService
      .play_url(game.publisher.username, game.name)
      .then(playUrl => {
        setPlayUrl(playUrl as string)
      })
      .catch(e => {
 
      })
    gameService
      .getRank(game)
      .then(r => {
        setGameRank(r.data)
      })
      .catch(e => {
 
      })
      
  }
  const isOwned = () => {
    return game.extras ? game.extras.price ? game.eligibility : true : true
  }
  useEffect(gameUpdate, [game])
  
  return (
    <Row >
      <Col xl={4}>
        <Image width="100%" src={game.icon_image as string}>
        </Image>
      </Col>
      <Col xl={14} style={{
        padding: 20
      }}>
        <Title>{game.display_name}</Title>
        <Paragraph>{game.description}</Paragraph>
        { 
          userProfile.email ? 
          isOwned() 
          ?  playUrl && <Button shape='round' type='primary' onClick={() => { setPlayVisible(true) }} >{ t('Play now') }<PlayCircleOutlined /></Button>
          : 
            <GamePayment game={game} onSuccess={() => {
              window.location.reload()
            }}/>
          : <Button shape='round' onClick={() => setLoginVisible(true)} type='primary' >{ t('Login to Play') }<PlayCircleOutlined /></Button>
        }
        <GamePlay gameUri={playUrl} game={game} visible={playVisible} onDone={() => setPlayVisible(false)} />
      </Col>
      <Col xs={24} xl={6}>
        <Summary
          stars={game.rate_avg}
          minimum_age={game.minimum_age}
          rank={gameRank}
          reviews={game.total_reviews || 0}
        />
        <Publisher username={game.publisher && game.publisher.username} />
      </Col>
    </Row >
  )
}

export default GameInfo